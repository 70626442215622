import { Partner } from '@/types/partner'

export const supportedActionsForPartner = (partner: Partner) => {
  if (!partner) return ['form']
  return Object.keys(partner.espFeatureSupport)
    .filter((feature) => feature)
    .filter((feature) => ['form', 'list', 'tag', 'event'].includes(feature))
}

export const integrationDepedencies = (partner: Partner) => {
  // find any segment groups with sync ups
  const { segmentGroups } = useSegmentGroupStore()
  const syncUpSegmentGroups = segmentGroups.filter(
    (sg) =>
      sg.syncTo === partner.name &&
      sg.syncToCustomField &&
      sg.syncToCustomField !== ''
  )

  // find any segments with auto segments set up for the partner
  const autoSegments = segmentGroups.filter((sg) => {
    return sg.signals.some((signal) =>
      definitionLogicItems(signal.definition).some(
        (logic) => logic.$source === partner.name
      )
    )
  })

  // find any opt-in offers that use the partner
  const { optinOffers } = useOfferStore()
  const offersUsingPartner = optinOffers.filter((o) => offerCanBeSubmitted(o))

  // find any flows with conditions in place that reference the partner
  const { flows } = useFlowStore()
  const flowsReferencingPartner = flows.filter((f) => {
    let found = false

    funnelDefinitionEach(f.funnelDefinition, (node) => {
      if (found) return
      if (node.type === 'yesno') {
        found = definitionLogicItems(node.condition).some(
          (logic) => logic.$source === partner.name
        )
      }
    })

    return found
  })

  return {
    syncUpSegmentGroups,
    autoSegments,
    offersUsingPartner,
    flowsReferencingPartner
  }
}

export const swapOutEspPlaceholder = (partner: Partner) => {
  const { configAsString } = useConfigStore()
  if (!configAsString.includes('espplaceholder')) return

  const { flows } = useFlowStore()
  flows.forEach((f) => {
    funnelDefinitionEach(f.funnelDefinition, (node) => {
      if (node.type === 'yesno') {
        definitionLogicItems(node.condition).forEach((logic) => {
          if (logic.$source === 'espplaceholder') {
            logic.$source = partner.name
          }
        })
      }
    })
  })

  const { segmentGroups } = useSegmentGroupStore()
  segmentGroups.forEach((sg) => {
    sg.signals.forEach((signal) => {
      definitionLogicItems(signal.definition).forEach((logic) => {
        if (logic.$source === 'espplaceholder') {
          logic.$source = partner.name
        }
      })
    })
  })
}

export const customFieldName = () => {
  const integrationStore = useIntegrationStore()
  const { integratedPartner } = integrationStore
  return (
    integratedPartner?.espFeatureSupport?.customField?.label || 'Custom Field'
  )
}

export const customFieldLabel = async (field: string) => {
  const integrationStore = useIntegrationStore()
  const { integratedPartner } = integrationStore
  if (integratedPartner?.espFeatureSupport?.customField?.unstructured)
    return field

  const data = await getIntegrationData('partner', 'custom_fields')
  return data.value.find((f) => String(f.id) === field)?.label
}
