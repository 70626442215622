import { defineStore } from 'pinia'
import { computed } from 'vue'
import { useConfigStore } from '@/stores/configStore'
import { makeId, idPrefixes, uuid } from '@/services/uuid'
import { offerSchema, Offer, Widget } from '@/types/config'

const formPartsDefault = {
  title: 'Opt-in to our glorious form',
  description: 'Enter your email below to receive free stuff.',
  buttonText: 'Join Now'
}

const offerPartsDefault = {
  title: 'Learn more about our amazing offer',
  description: `It's very, very cool. Click below to learn more.`,
  buttonText: 'Learn More'
}

export const useOfferStore = defineStore('offer', () => {
  const configStore = useConfigStore()
  const { config, isLoading, isError } = storeToRefs(configStore)

  const templateStore = useTemplateStore()
  const { offers: tmpOffers } = storeToRefs(templateStore)

  const offers = computed(() => {
    const realOffers = config.value?.rightCta?.offers || []
    return [...realOffers, ...tmpOffers.value].sort((a, b) =>
      a.name.localeCompare(b.name)
    )
  })
  const optinOffers = computed(() => offers.value.filter((o) => isForm(o)))
  const ctaOffers = computed(() => offers.value.filter((o) => !isForm(o)))

  const getById = (id: string) => offers.value.find((offer) => offer.id === id)

  const create = ({
    type,
    offer
  }: {
    type: 'form' | 'offer'
    offer?: Partial<Offer>
  }) => {
    const namePrefix = type === 'form' ? 'Form' : 'Offer'
    const partsDefault = type === 'form' ? formPartsDefault : offerPartsDefault
    const triggeredAction =
      type === 'form'
        ? {
            type: 'form',
            settings: {
              partnerName: '',
              formIds: [],
              thanksForConvertingText: '',
              redirectFunnel: { _uuid: uuid(), type: 'root', child: [] },
              formSettings: {
                showLabels: false
              },
              fields: [
                {
                  label: 'Email address',
                  placeholder: 'Your email address',
                  displayType: 'email',
                  width: 6,
                  options: {}
                },
                {
                  displayType: 'submit',
                  width: 6,
                  options: {}
                }
              ]
            }
          }
        : { type: 'link', settings: { url: '', open: false } }
    const newOffer = offerSchema.parse({
      id: makeId(idPrefixes.OFFER),
      name: `New ${namePrefix} ${offers.value.length + 1}`,
      isEnabled: true,
      parts: partsDefault,
      triggeredAction,
      ...offer
    }) as Offer

    config.value?.rightCta?.offers?.push(newOffer)

    recordAnalyticsEvent(
      type === 'form'
        ? AnalyticsEventTypes.FORM_CREATED
        : AnalyticsEventTypes.OFFER_CREATED,
      {
        id: newOffer.id,
        name: newOffer.name
      }
    )

    return newOffer
  }

  const destroy = (offer: Offer) => {
    if (!config.value?.rightCta) return
    const foundIndex = config.value.rightCta.offers.findIndex(
      (o) => o.id == offer.id
    )
    if (foundIndex === -1) return // this should really never happen

    funnelDefinitionForEachFlow((node) => {
      if (node.type === 'offer' && node.offerId === offer.id) {
        node.offerId = null
      }
    })

    config.value?.rightCta?.offers?.splice(foundIndex, 1)
  }

  const duplicate = (offer: Offer) => {
    const newOffer = {
      ...klona(offer),
      id: makeId(idPrefixes.OFFER),
      name: `${offer.name} (Copy)`
    }

    config.value?.rightCta?.offers?.push(newOffer)

    return newOffer
  }

  return {
    offers,
    optinOffers,
    ctaOffers,
    getById,
    isLoading,
    isError,
    create,
    destroy,
    duplicate
  }
})
