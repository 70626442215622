export default {
  root: ({ parent, context }) => {
    return {
      class: [
        'border-b-4 pb-2 min-w-[100px] md:min-w-fit',
        // Colors and Conditions
        {
          'border-transparent dark:text-white/90': !context.active,
          '': !context.active,

          'border-surface-500 font-bold': context.active,
          'text-surface-900 dark:text-white': context.active
        },

        // States
        'focus-visible:outline-none focus-visible:outline-offset-0 focus-visible:ring focus-visible:ring-inset',
        'focus-visible:ring-primary-400/50 dark:focus-visible:ring-primary-300/50',
        {
          '': !context.active,
          'hover:border-surface-400 dark:hover:border-primary-400':
            !context.active,
          'hover:text-surface-900 dark:hover:text-surface-0': !context.active
        },

        // Transitions
        'transition-all duration-200',

        // Misc
        'cursor-pointer select-none text-decoration-none',
        'overflow-hidden',
        'user-select-none'
      ]
    }
  },
  inkbar: ({ props }) => ({
    class: [
      //Size and Shape
      'w-0 hidden'
    ]
  })
}
