export const capitalize = (x: string) => {
  if (x && x.length) {
    const firstLetterIndex = x.search(/[a-zA-Z]/)
    if (firstLetterIndex !== -1) {
      return (
        x.slice(0, firstLetterIndex) +
        x[firstLetterIndex].toUpperCase() +
        x.slice(firstLetterIndex + 1)
      )
    }
  }
  return x
}

export const singularize = (x: string) => {
  return x.endsWith('s') ? x.slice(0, -1) : x
}

export const pluralize = (n: number, singular: string, plural: string) => {
  return n + ' ' + (n === 1 ? singular : plural)
}

export const sluggify = (x: string) => {
  return x.toLowerCase().replace(/ /g, '-')
}

export const stripHtml = (x: string | null) => {
  if (!x) return ''
  const div = document.createElement('div')
  div.innerHTML = x
  return div.innerText
}

export const toSentence = (x: string[]) => {
  if (x.length === 1) {
    return x[0]
  }
  return x.slice(0, x.length - 1).join(', ') + ' and ' + x[x.length - 1]
}

export const copyToClipboard = (text: string) => {
  const notificationStore = useNotificationStore()
  if (navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        notificationStore.addNotification('Copied to clipboard')
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err)
      })
  } else {
    // Fallback for browsers that don't support the Clipboard API
    const textArea = document.createElement('textarea')
    textArea.value = text
    document.body.appendChild(textArea)
    textArea.select()
    try {
      document.execCommand('copy')
      notificationStore.addNotification('Copied to clipboard')
    } catch (err) {
      console.error('Failed to copy text: ', err)
    }
    document.body.removeChild(textArea)
  }
}
