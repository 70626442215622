import { useNotificationStore } from '@/stores/notificationStore'
import api from '@/services/axiosApi'
import { useAiStore } from '@/stores/aiStore'

export function useClaudeApi() {
  const aiStore = useAiStore()
  const { isGenerating } = storeToRefs(aiStore)
  const notificationStore = useNotificationStore()
  const handleNotificationError = () => {
    notificationStore.addError(
      'Sorry! There seems to be an issue with the backend AI server. Please try again later.'
    )
  }
  const callBackendApi = async (endpoint: string, data: any): Promise<any> => {
    const teamStore = useTeamStore()
    const { currentTeam } = storeToRefs(teamStore)
    const teamPid = currentTeam.value?.pid
    // const backendUrl = import.meta.env.VITE_APP_BACKEND_HOST
    aiStore.setIsGenerating(true)
    try {
      const response = await api.post(`/api/${teamPid}/ai/${endpoint}`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })

      if (response.data) {
        return response.data
      } else {
        handleNotificationError()
        throw new Error('Unexpected API response format')
      }
    } catch (error) {
      handleNotificationError()
      console.error(`Error calling backend API (${endpoint}):`, error)
      throw error
    } finally {
      aiStore.setIsGenerating(false)
    }
  }

  const modifyTemplate = async (
    prompt: string,
    template: any
  ): Promise<any> => {
    return await callBackendApi('modifyTemplate', {
      instructions: prompt,
      template: JSON.stringify(template)
    })
  }

  const bootstrapFromTemplate = async (prompt: string): Promise<any> => {
    return await callBackendApi('bootstrapFromTemplate', {
      instructions: prompt
    })
  }

  const questionAnalysis = async (context: string): Promise<any> => {
    return await callBackendApi('questionAnalysis', { context })
  }

  const createQuestionInternalId = async (question: string): Promise<any> => {
    const response = await callBackendApi('createQuestionInternalId', {
      question
    })
    return response.internalId
  }

  const summarizeQuestion = async (question: string): Promise<string> => {
    const response = await callBackendApi('generateSegmentGroupId', {
      question
    })
    return response.segmentGroupName
  }

  const createQuestion = async (
    instruction: any,
    questionsInFlow: any
  ): Promise<any> => {
    return await callBackendApi('createQuestion', {
      instruction,
      questionsInFlow
    })
  }

  const createOffer = async (
    instruction: any,
    type: string,
    questionsInFlow: any,
    existingOffersAndForms: any
  ): Promise<any> => {
    return await callBackendApi('createOffer', {
      instruction,
      type,
      questionsInFlow,
      existingOffersAndForms
    })
  }

  const generateSyncValues = async (segmentNames: string[]): Promise<any> => {
    return await callBackendApi('generateSyncValues', { segmentNames })
  }

  const generatePersonalizationTokens = async (
    surroundingContent: string,
    segmentGroupName: string,
    defaultText: string,
    variants: array
  ): Promise<any> => {
    return await callBackendApi('generatePersonalizationTokens', {
      surroundingContent,
      segmentGroupName,
      defaultText,
      variants
    })
  }

  const augmentFlow = async (payload: any): Promise<any> => {
    return await callBackendApi('augmentFlow', payload)
  }

  const getBusinessDescriptors = async (signals: any): Promise<any> => {
    return await callBackendApi('getBusinessDescriptors', { signals })
  }

  const createPersonalGoals = async (payload: {
    json: any
    context: any
  }): Promise<any> => {
    return await callBackendApi('createPersonalGoals', payload)
  }

  const generateFromOnboardingTemplate = async (payload: {
    goal: any
    context: any
    inputs: any
  }): Promise<any> => {
    return await callBackendApi('generateFromOnboardingTemplate', payload)
  }

  const generateSegmentGroupCombinations = async (payload: string) => {
    return await callBackendApi('generateSegmentGroupCombinations', {
      payload
    })
  }

  return {
    summarizeQuestion,
    createQuestion,
    createOffer,
    generateSyncValues,
    augmentFlow,
    getBusinessDescriptors,
    createPersonalGoals,
    generateFromOnboardingTemplate,
    questionAnalysis,
    createQuestionInternalId,
    isGenerating,
    bootstrapFromTemplate,
    modifyTemplate,
    generatePersonalizationTokens,
    generateSegmentGroupCombinations
  }
}
