export const ROUTES = {
  HOME: 'home',
  LOGIN: 'login',
  LOGOUT: 'logout',
  ACCEPTINVITATION: 'acceptInvitation',
  FORGOTPASSWORD: 'forgot-password',
  RESETPASSWORD: 'reset-password',
  REGISTER: 'register',
  SETUPBLANKUSER: 'setupBlankUser',
  DASHBOARD_INDEX: 'dashboardIndex',
  DASHBOARD: 'dashboard',
  FEED: 'feed',
  INTEGRATIONS: 'integrations',
  INTEGRATION: 'integration',
  TEMPLATES: 'templates',
  TEMPLATE: 'template',
  ANNOUNCEMENTS: 'announcements',
  USERS: 'users',
  USER: 'user',
  METRICS: 'metrics',
  SETTINGS: 'settings',
  PROFILE: 'profile',
  ACCOUNTS: 'accounts',
  SECURITY: 'security',
  CREATECAMPLAIGNWITHINDASHBOARD: 'createCampaignWithinDashboard',
  CAMPAIGNS: 'campaigns',
  CREATECAMPAIGN: 'createCampaign',
  CAMPAIGN: 'campaign',
  EMAILS: 'emails',
  CREATEEMAIL: 'createEmail',
  EMAIL: 'email',
  GOAL: 'goal',
  GOALS: 'goals',
  CREATEGOAL: 'createGoal',
  ATTRIBUTION: 'attribution',
  SEGMENTGROUPSPARENT: 'segmentGroupsParent',
  SEGMENTGROUPS: 'segmentGroups',
  SEGMENTGROUPTEMPLATES: 'segmentGroupTemplates',
  QUESTIONS: 'questions',
  SEGMENTGROUP: 'segmentGroup',
  SEGMENTGROUPCREATE: 'segmentGroupCreate',
  QUESTION: 'question',
  QUESTIONCREATE: 'questionCreate',
  CREATESEGMENTGROUPWITHINDASHBOARD: 'createSegmentGroupWithinDashboard',
  WIDGET: 'widget',
  CREATEWIDGET: 'widgetCreate',
  WIDGETOFFERFUNNEL: 'widget:offerFunnel',
  WIDGETOFFERFUNNELOFFER: 'widget:offerFunnel:offer',
  WIDGETOFFERFUNNELSEGMENTGROUP: 'widget:offerFunnel:segmentGroup',
  WIDGETOFFER: 'widget:offer',
  OFFERFUNNELWIDGET: 'offerFunnel:widget',
  OFFERFUNNELOFFER: 'offerFunnel:offer',
  OFFERFUNNELSEGMENTGROUP: 'offerFunnel:segmentGroup',
  FORMS: 'forms',
  OFFERS: 'offers',
  OFFER: 'offer',
  FORM: 'form',
  OFFERFUNNELS: 'offerFunnels',
  OFFERFUNNEL: 'offerFunnel',
  CREATEOFFERFUNNEL: 'offerFunnelCreate',
  OFFERFUNNELNODE: 'offerFunnelNode',
  WIDGETS: 'widgets',
  INSIGHTS: 'insights',
  NOTFOUND: 'NotFound',
  NOTFOUNDAUTH: 'NotFoundAuth',
  ONBOARDING: 'onboarding',
  UPGRADE: 'upgrade',
  PAGES: 'pages'
}
