const aggregateTimeSeries = (timeSeries, key) => {
  return (timeSeries || []).reduce((sum, entry) => sum + entry[key], 0)
}

export const processChartData = (coreData, builderData, builderParams) => {
  const reportStore = useReportStore()

  const offerStore = useOfferStore()
  const { optinOffers } = storeToRefs(offerStore)

  const optinRate = computed(() => calculateOptinRate(unref(builderData)))
  const optinRateChange = computed(() =>
    calculateOptinRate(unref(builderData), 'change')
  )

  const optinRateEnabled = computed(() => {
    if (builderParams.value.selectedOffers.length > 0) {
      return true
    }
    return optinOffers.value.length > 0 && optinRate.value !== '0.00%'
  })

  const offerConversions = calculateOfferConversions(unref(coreData))
  const prevOfferConversions = calculateOfferConversions(
    unref(coreData.value?.previousPeriod)
  )
  const percentChangeForOfferConversions =
    prevOfferConversions === 0
      ? null
      : (
          ((offerConversions - prevOfferConversions) / prevOfferConversions) *
          100
        ).toFixed(1) + '%'

  const answerCount = calculateAnswerCount(unref(coreData))
  const prevAnswerCount = calculateAnswerCount(
    unref(coreData.value?.previousPeriod)
  )
  const percentChangeForAnswerCount =
    prevAnswerCount === 0
      ? null
      : (((answerCount - prevAnswerCount) / prevAnswerCount) * 100).toFixed(1) +
        '%'

  const enrichments = calculateEnrichments(unref(coreData))
  const prevEnrichments = calculateEnrichments(
    unref(coreData.value?.previousPeriod)
  )

  const percentChangeForEnrichments =
    prevEnrichments === 0
      ? null
      : (((enrichments - prevEnrichments) / prevEnrichments) * 100).toFixed(1) +
        '%'

  const { canAccess } = usePermissionStore()

  const personalizationLift = calculateConversionRateLift(unref(coreData))
  const { campaigns } = useCampaignStore()
  const anyCampaignHasTests = campaigns.some((campaign) =>
    campaignHasTestsRunning(campaign)
  )
  const enablePersonalizationReport =
    (personalizationLift?.control?.exposures > 0 ||
      personalizationLift?.experiment?.exposures > 0) &&
    canAccess('personalization-campaign') &&
    anyCampaignHasTests

  return [
    {
      id: 'optins',
      name: 'opt-in rate',
      total: optinRate,
      change: optinRateChange,
      direction:
        parseFloat(optinRateChange?.value) > 0 ? 'increase' : 'decrease',
      data: unref(builderData),
      loading: !unref(builderData),
      enabled: optinRateEnabled.value
    },
    {
      id: 'offers',
      name: 'CTA conversions',
      total: new Intl.NumberFormat().format(offerConversions),
      change: percentChangeForOfferConversions,
      direction:
        parseFloat(percentChangeForOfferConversions) > 0
          ? 'increase'
          : 'decrease',
      data: unref(coreData),
      loading: !unref(coreData),
      enabled: offerConversions !== 0
    },
    {
      id: 'answers',
      name: 'answers',
      total: new Intl.NumberFormat().format(
        calculateAnswerCount(unref(coreData))
      ),
      change: percentChangeForAnswerCount,
      direction:
        parseFloat(percentChangeForAnswerCount) > 0 ? 'increase' : 'decrease',
      data: unref(coreData),
      loading: !unref(coreData),
      enabled: true
    },
    {
      id: 'enrichments',
      name: 'contacts enriched',
      total: new Intl.NumberFormat().format(enrichments),
      change: percentChangeForEnrichments,
      direction:
        parseFloat(percentChangeForEnrichments) > 0 ? 'increase' : 'decrease',
      data: unref(coreData),
      loading: !unref(coreData),
      enabled: true
    },
    {
      id: 'personalizations',
      name: 'conversion lift',
      total: showConversionLift(personalizationLift),
      data: unref(coreData),
      loading: !unref(coreData),
      enabled: enablePersonalizationReport
    }
  ]
}

const calculateOptinRate = (
  builderData: object,
  field: string = 'overallBucket'
) => {
  if (!builderData) return

  if (field === 'change') {
    if (!builderData['overallBucket'].change) return null
    return (builderData['overallBucket'].change * 100).toFixed(1) + '%'
  }

  return (
    (
      (builderData[field].denominator
        ? builderData[field].numerator / builderData[field].denominator
        : 0) * 100
    ).toFixed(2) + '%'
  )
}

const calculateOfferConversions = (coreData) => {
  if (!coreData) return
  return coreData.totalVisitorOffersConverted
  return aggregateTimeSeries(
    coreData.offersData.timeSeries,
    'visitorsConverted'
  )
}

const calculateAnswerCount = (coreData) => {
  if (!coreData) return
  return coreData.totalAnswers
  return aggregateTimeSeries(
    coreData.questionsData.timeSeries,
    'visitorsConverted'
  )
}

const calculateEnrichments = (coreData) => {
  if (!coreData) return
  return coreData.totalNewEnriches
}

const calculateConversionRateLift = (data) => {
  if (!data) return
  // Extract conversions and exposures
  const { conversions, exposures } = data

  // Initialize counters for control and experiment groups
  const control = {
    conversions: 0,
    exposures: 0
  }

  const experiment = {
    conversions: 0,
    exposures: 0
  }

  // Sum conversion results
  conversions.forEach((conv) => {
    const sum = conv.result
    if (conv.holdback) {
      control.conversions += sum
    } else {
      experiment.conversions += sum
    }
  })

  // Count exposures
  exposures.forEach((exp) => {
    if (exp.holdback) {
      control.exposures += exp.result
    } else {
      experiment.exposures += exp.result
    }
  })

  // Calculate conversion rates
  const controlRate = control.conversions / control.exposures
  const experimentRate = experiment.conversions / experiment.exposures

  // if (isNaN(controlRate)) {
  //   return null
  // }

  // Calculate lift
  let lift = ((experimentRate - controlRate) / controlRate) * 100

  if (isNaN(lift)) {
    lift = 0
  }

  return {
    control: {
      ...control,
      rate: controlRate
    },
    experiment: {
      ...experiment,
      rate: experimentRate
    },
    lift: lift,
    absoluteDifference: experimentRate - controlRate
  }
}

const showConversionLift = (result) => {
  if (!result) return
  if (result.lift === Infinity || result.lift === 0) return 'N/A'
  const liftSign = result.lift >= 0 ? '+' : ''
  return liftSign + result.lift.toFixed(2) + '%'
}

// Add other data processing functions as needed
