import { defineStore } from 'pinia'
import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query'
import { useDebounceFn } from '@vueuse/core'
import { klona } from 'klona'
import { uuid } from '@/services/uuid'
import api from '@/services/axiosApi'
import { Config } from '@/types/config'
import { Team } from '@/types/team'
import { AnalyticsEventTypes } from '@/services/analytics'
import * as jsondiffpatch from 'jsondiffpatch'

export enum PublishState {
  Loading,
  Published,
  Dirty,
  Saving,
  Saved
}

let tabId: string

export const useConfigStore = defineStore('config', () => {
  tabId = uuid()
  const fullResponse = ref(null)
  const currentTeamPid = ref<number | null>(null)
  const config = ref<Config | null>(null)
  const snapshots = ref<[]>([])
  const teamMembers = ref<
    {
      id: number
      name: string
      email: string
      membership: { role: string }[]
    }[]
  >([])
  const currentTeam = ref<Team | null>(null)
  const lastSavedConfig = ref<Config | null>(null)
  const lastChecksum = ref(null)
  const publishState = ref<PublishState>(PublishState.Loading)
  const savesMade = ref(0)
  const isDirty = ref(false)

  const queryClient = useQueryClient()

  const configQuery = useQuery({
    queryKey: ['config', currentTeamPid],
    queryFn: () =>
      api
        .get(`/api/${currentTeamPid.value}/configs/current`)
        .then((res) => res.data),
    enabled: () => !!currentTeamPid.value,
    retry: false
  })

  watch(currentTeamPid, (newVal, oldVal) => {
    if (oldVal) {
      Echo.leave(`config-changes.${oldVal}`)
    }
    if (newVal) {
      Echo.private(`config-changes.${newVal}`).listen(
        'DraftConfigUpdated',
        (data) => {
          if (data.checksum !== lastChecksum.value) {
            configQuery.refetch()
          }
          if (data.message) {
            const notificationStore = useNotificationStore()
            notificationStore.addNotification(data.message)
          }
        }
      )
    }
  })

  const configAsString = computed(() => {
    return JSON.stringify(config.value)
  })

  const owner = computed(() => {
    return fullResponse.value?.owner
  })

  const loadSnapshotMutation = useMutation({
    mutationFn: (configId) =>
      api
        .post(`/api/${currentTeamPid.value}/configs/load_snapshot/${configId}`)
        .then((res) => res.data),
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: ['snapshots'] })
    }
  })
  const deleteSnapshotMutation = useMutation({
    mutationFn: (configId) =>
      api
        .delete(`/api/${currentTeamPid.value}/configs/${configId}`)
        .then((res) => res.data),
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: ['snapshots'] })
    }
  })

  watchEffect(() => {
    if (configQuery.data.value) {
      fullResponse.value = klona(unref(configQuery.data))
      lastSavedConfig.value = klona(fullResponse.value.draft)
      if (!lastSavedConfig.value) {
        publishState.value = PublishState.Published
      } else {
        publishState.value = PublishState.Saved
      }
      lastChecksum.value = fullResponse.value.checksum
      const configData = fullResponse.value?.draft || fullResponse.value?.stable
      config.value = klona({ ...configData, id: fullResponse.value?.id })

      currentTeam.value = klona(fullResponse.value.team)
      teamMembers.value = klona(fullResponse.value.members)
    }
  })

  watch(
    config,
    (newConfig) => {
      if (newConfig) {
        debouncedUpdateConfig(newConfig)
      }
    },
    { deep: true }
  )

  const saveSnapshot = async (description: string) => {
    if (!config.value) return
    try {
      const data = await updateConfigAsync({
        config: config.value,
        snapshotDescription: description
      })
      queryClient.invalidateQueries({ queryKey: ['snapshots'] })
      // Invalidate snapshotConfig
    } catch (err) {}
  }

  const {
    mutate: updateConfig,
    mutateAsync: updateConfigAsync,
    isPending: isUpdatingConfig
  } = useMutation({
    mutationFn: (variables: {
      config: Config
      snapshotDescription?: string
    }) => {
      return api
        .post(`/api/${currentTeamPid.value}/configs/draft`, {
          data: variables.config,
          checksum: lastChecksum.value,
          tabId,
          snapshot_description: variables.snapshotDescription
        })
        .then((res) => res.data)
    },
    onSuccess: (response) => {
      //lastSavedConfig.value = klona(config.value)
      lastChecksum.value = response.data.checksum
      savesMade.value += 1
      publishState.value = PublishState.Dirty
    }
  })

  const debouncedUpdateConfig = useDebounceFn((newConfig: Config) => {
    const from = JSON.stringify(toRaw(newConfig))
    const to = JSON.stringify(toRaw(lastSavedConfig.value))

    const diff = jsondiffpatch.diff(
      toRaw(newConfig),
      toRaw(lastSavedConfig.value)
    )
    console.log(diff)

    // Check if diff only contains accessedAt changes
    const onlyAccessedAtChanges = diff ? isOnlyAccessedAtChanges(diff) : false

    if (to && from !== to && !onlyAccessedAtChanges) {
      updateConfig({ config: newConfig })
      isDirty.value = true
    } else if (to && from !== to) {
      // Still update config but don't mark as dirty if only accessedAt changed
      updateConfig({ config: newConfig })
    }
  }, 2000) // 500ms debounce time, adjust as needed

  // Helper function to check if diff only contains accessedAt changes
  const isOnlyAccessedAtChanges = (diff: any): boolean => {
    // If diff is null or undefined, there are no changes
    if (!diff) return true

    // Function to recursively check objects
    const checkObject = (obj: any): boolean => {
      // If it's not an object or is null, it's not an accessedAt change
      if (!obj || typeof obj !== 'object') return false

      // Check each key in the object
      for (const key in obj) {
        // Skip the _t property which is used by jsondiffpatch for arrays
        if (key === '_t') continue

        const value = obj[key]

        // If the key is 'accessedAt' and it's an array with 2 elements (old and new value)
        if (
          key === 'accessedAt' &&
          Array.isArray(value) &&
          value.length === 2
        ) {
          continue // This is an accessedAt change, which is fine
        } else if (typeof value === 'object' && value !== null) {
          // Recursively check nested objects
          if (!checkObject(value)) return false
        } else {
          // Found a non-accessedAt change
          return false
        }
      }

      // If we got here, all changes were accessedAt or contained objects with only accessedAt changes
      return true
    }

    return checkObject(diff)
  }

  const publishConfigMutation = useMutation({
    mutationFn: (config: Config) =>
      api
        .post(`/api/${currentTeamPid.value}/configs/publish`, {
          data: config,
          checksum: lastChecksum.value,
          tabId
        })
        .then((res) => res.data),
    onSuccess: (response) => {
      //lastSavedConfig.value = klona(config.value)
      lastChecksum.value = response.checksum
      savesMade.value += 1
      publishState.value = PublishState.Published
      isDirty.value = false
      currentTeam.value = klona(response.team)
    }
  })

  const publish = () => {
    if (!config.value) return
    publishConfigMutation.mutate(config.value)
    recordAnalyticsEvent(AnalyticsEventTypes.PUBLISHED)
  }

  const validateForPublish = () => {
    let errors = []
    // are there any forms in use && we don't have an integration?
    const integrationStore = useIntegrationStore()
    const widgetStore = useWidgetStore()
    const offerStore = useOfferStore()
    if (
      !integrationStore.integratedPartner &&
      offerStore.optinOffers.some(
        (o) =>
          offerUsedBy(o).filter(
            (flow) => widgetStore.getWidgetsForOfferFunnel(flow.id).length > 0
          ).length > 0
      )
    ) {
      errors.push({
        message:
          "You have Flows in place that use opt-in forms, and you haven't yet set up an integration to an email marketing platform / CRM.",
        references: []
      })
    }
    if (
      integrationStore.integratedPartner &&
      offerStore.optinOffers.some(
        (o) => offerIsInUse(o) && !offerCanBeSubmitted(o)
      )
    ) {
      errors.push({
        message:
          'You have forms in use that are not currently configured to submit to your email marketing platform / CRM.',
        references: []
      })
    }

    if (
      !integrationStore.integratedPartner &&
      configAsString.value.includes('espplaceholder')
    ) {
      errors.push({
        message: `You have Flows or segments in place that currently reference a visitor's contact status (i.e. anonymous or known contact), but you haven't yet set up an integration with your email marketing platform or CRM.`,
        references: []
      })
    }
    return errors
  }

  const associatedWebsites = computed(() => {
    return fullResponse.value?.teams
  })

  return {
    publishState,
    isDirty,
    currentTeamPid,
    fullResponse,
    owner,
    config,
    configAsString,
    currentTeam,
    lastChecksum,
    savesMade,
    isLoading: configQuery.isLoading,
    isError: configQuery.isError,
    configQuery,
    saveSnapshot,
    loadSnapshotMutation,
    deleteSnapshotMutation,
    snapshots,
    isUpdatingConfig,
    publish,
    validateForPublish,
    teamMembers,
    associatedWebsites,
    debouncedUpdateConfig
  }
})
