import { defineStore } from 'pinia'

type Notification = {
  message: string
  type: 'error' | 'success' | undefined
  sticky?: boolean
}

export const useNotificationStore = defineStore('notification', () => {
  const notifications = ref<Notification[]>([])

  const add = (payload) => {
    notifications.value.push(payload)
  }

  const addNotification = (message: string) => {
    notifications.value.push({ message, type: 'success' })
  }

  const addError = (message: string, sticky: boolean = false) => {
    notifications.value.push({
      message,
      type: 'error',
      sticky
    })
  }

  const removeNotification = (index: number) => {
    notifications.value.splice(index, 1)
  }

  return {
    notifications,
    add,
    addNotification,
    addError,
    removeNotification
  }
})
