import { Liquid } from 'liquidjs'

import {
  Dimension,
  Segment,
  LogicDefinition,
  LogicDefinitionItem
} from '@/types/config'

import { useClaudeApi } from '@/composables/useClaudeApi'

export const renderText = (t, truncationLimit = 100) => {
  const phraseStore = usePhraseStore()
  if (!t) return false

  let tmp = document.createElement('DIV')
  tmp.innerHTML = t

  // Replace Liquid fragments with fallback text
  let text = tmp.innerHTML.replace(
    /{{ phrases\.(\w+) }}/g,
    (match, phraseId) => {
      const phrase = phraseStore.getById(phraseId)
      return phrase ? phrase.fallbackText : match
    }
  )

  const engine = new Liquid()
  text = engine.parseAndRenderSync(text, {})

  // Convert HTML to plain text
  tmp.innerHTML = text
  text = tmp.textContent || tmp.innerText

  return text.length > truncationLimit
    ? text.substring(0, truncationLimit) + '...'
    : text
}

export const linkToSegmentGroup = (segmentGroup: Dimension) => {
  return isAQuestion(segmentGroup)
    ? { name: ROUTES.QUESTION, params: { segmentGroupId: segmentGroup.id } }
    : { name: ROUTES.SEGMENTGROUP, params: { segmentGroupId: segmentGroup.id } }
}

export const renderTextForQuestion = (question) => {
  if (!question || !question.text) return false
  return renderText(question.text)
}

export const isAQuestion = (segmentGroup: Dimension) => {
  const questionStore = useQuestionStore()
  const question = questionStore.getBySegmentGroupId(segmentGroup.id)
  if (!question) return false
  const renderedText = renderTextForQuestion(question)
  if (renderedText === false) return false
  return renderedText !== ''
}

export const isArchived = (segmentGroup: Dimension) => {
  return segmentGroup.isArchived
}

export const isASegmentGroup = (segmentGroup: Dimension) => {
  return !isAQuestion(segmentGroup)
}

export const isOpenEndedQuestion = (segmentGroup: Dimension) => {
  return segmentGroup.segments.some((segment) => {
    return isBuiltInOther(segment)
  })
}

export const getOtherSegmentForSegmentGroup = (segmentGroup: Dimension) => {
  return segmentGroup.segments.find((segment) => {
    return isBuiltInOther(segment)
  })
}

export const isBuiltInOther = (segment: Segment) => {
  return segment.name === 'INT_OTHER'
}

export const doesAnswerHaveConditions = (segment: Segment) => {
  const segmentGroupStore = useSegmentGroupStore()
  const segmentGroup = segmentGroupStore.segmentGroupForSegmentId(segment.id)
  if (!segmentGroup) return false
  const questionStore = useQuestionStore()
  const question = questionStore.getBySegmentGroupId(segmentGroup.id)
  if (!question) return false
  const questionOption = question.options.find(
    (option) => option.segmentId === segment.id
  )

  return definitionLogicItems(questionOption.conditions).length > 0
}

export const segmentName = (segment: Segment): string | null => {
  if (segment && segment.name === 'INT_OTHER') {
    const segmentGroupStore = useSegmentGroupStore()
    const segmentGroup = segmentGroupStore.segmentGroupForSegmentId(segment.id)
    if (!segmentGroup) return 'Other'
    const questionStore = useQuestionStore()
    const question = questionStore.getBySegmentGroupId(segmentGroup.id)
    return question?.otherOptions?.otherLabel || null
  }
  return segment ? segment.name : null
}

export const definitionLogicItems = (definition: LogicDefinition) => {
  return [...(definition.or || []), ...(definition.and || [])]
    .flatMap((def) => def)
    .map((def) => [...(def.and || []), ...(def.or || [])])
    .flatMap((def) => def)
}

export const autoSyncLogicDefinitionForSegment = (segment: Segment) => {
  const segmentGroupStore = useSegmentGroupStore()
  const segmentGroup = segmentGroupStore.segmentGroupForSegmentId(segment.id)
  if (segmentGroup) {
    const { integratedPartner } = useIntegrationStore()
    const customFieldId = segmentGroup.syncToCustomField
    const signal = signalForSegment(segmentGroup, segment)

    if (!integratedPartner || !customFieldId) return null

    if (signal) {
      const logicItem = definitionLogicItems(signal.definition).find(
        (logic: LogicDefinitionItem) =>
          logic.$type === 'customField' &&
          logic.$source === integratedPartner.name &&
          logic.operator === 'equals' &&
          logic.customFieldId === customFieldId
      )
      if (logicItem) {
        return logicItem
      }
    } else {
    }
  }
  return ''
}

export const indexOfSyncUpDefinition = (
  definition: LogicDefinition,
  segmentGroup: Dimension
) => {
  const { integratedPartner } = useIntegrationStore()
  const customFieldId = segmentGroup.syncToCustomField

  if (!definition || !integratedPartner) return -1

  for (let i = 0; i < (definition.or || []).length; i++) {
    const orSignal = definition.or[i]

    if (orSignal.and && orSignal.and.length === 1) {
      const andSignal = orSignal.and[0]

      if (
        andSignal.$source === integratedPartner.name &&
        andSignal.$type === 'customField' &&
        andSignal.operator === 'equals' &&
        (andSignal.customFieldId === customFieldId ||
          (!andSignal.customFieldId && !customFieldId))
      ) {
        return i
      }
    }
  }

  return -1
}

export const segmentGroupSyncSetupCompletion = (segmentGroup: Dimension) => {
  const integrationStore = useIntegrationStore()
  const { integratedPartner } = storeToRefs(integrationStore)

  if (!segmentGroup) return 'no_segment_group'

  if (!integratedPartner.value) return 'no_integration'

  if (!segmentGroup.syncToCustomField) return 'no_field'

  const numberOfSyncedSegments = segmentGroup.segments.reduce(
    (acc, segment) => {
      const signal = segmentGroup.signals.find(
        (signal) => signal.indicates === segment.id
      )

      if (!signal) return acc

      const syncConditionIndex = indexOfSyncUpDefinition(
        signal.definition,
        segmentGroup
      )

      if (syncConditionIndex > -1) {
        const andSignal = signal.definition.or[syncConditionIndex].and[0]

        if (andSignal.value) {
          return acc + 1
        }
      }

      return acc
    },
    0
  )

  if (numberOfSyncedSegments === 0) return 'no_values'

  if (numberOfSyncedSegments === segmentGroup.segments.length)
    return 'all_values'

  return 'some_values'
}

export const logicDefinitionsEach = (
  segmentGroup: Dimension,
  callback: (...args: Array<any>) => any,
  nuke?: (...args: Array<any>) => any
) => {
  // Helper function to process nested definitions
  const processDefinition = (
    definition: LogicDefinition,
    parentArray: any[],
    index: number
  ) => {
    let wasNuked = false

    // Process 'or' conditions
    if (definition.or) {
      for (let i = definition.or.length - 1; i >= 0; i--) {
        const orItem = definition.or[i]
        let itemNuked = false

        callback(orItem, () => {
          definition.or.splice(i, 1)
          itemNuked = true
        })

        // Only process nested items if this item wasn't nuked
        if (!itemNuked && orItem.and) {
          for (let j = orItem.and.length - 1; j >= 0; j--) {
            const andItem = orItem.and[j]
            callback(andItem, () => {
              orItem.and.splice(j, 1)
            })
          }
        }
      }
    }

    // Process 'and' conditions
    if (definition.and) {
      for (let i = definition.and.length - 1; i >= 0; i--) {
        const andItem = definition.and[i]
        let itemNuked = false

        callback(andItem, () => {
          definition.and.splice(i, 1)
          itemNuked = true
        })

        // Only process nested items if this item wasn't nuked
        if (!itemNuked && andItem.or) {
          for (let j = andItem.or.length - 1; j >= 0; j--) {
            const orItem = andItem.or[j]
            callback(orItem, () => {
              andItem.or.splice(j, 1)
            })
          }
        }
      }
    }

    return wasNuked
  }

  // Process each signal's definition
  for (let i = segmentGroup.signals.length - 1; i >= 0; i--) {
    const signal = segmentGroup.signals[i]

    // Skip calling callback on the signal itself, just process its definition
    if (signal.definition) {
      processDefinition(signal.definition, segmentGroup.signals, i)
    }
  }
}

export const logicDefinitionForEachSegmentGroup = (
  callback: (...args: Array<any>) => any
) => {
  const segmentGroupStore = useSegmentGroupStore()

  // Iterate through each segment group
  segmentGroupStore.segmentGroups.forEach((segmentGroup) => {
    logicDefinitionsEach(
      segmentGroup,
      callback,
      // Pass through the nuke capability
      () => {
        // If a top-level nuke is called, we don't actually want to remove the segment group,
        // as we're just iterating through logic definitions
        console.warn('Nuke called at segment group level - ignoring')
      }
    )
  })
}

export const conditionEach = (
  definition: any,
  callback: (...args: Array<any>) => any,
  nuke?: (...args: Array<any>) => any
) => {
  if (!definition) return

  let nuked = false

  // If it's a condition (has $type), process it
  if (definition.$type) {
    callback(definition, () => {
      if (nuke) {
        nuke()
        nuked = true
      }
    })
    return
  }

  if (nuked) return

  // Process 'and' conditions
  if (definition.and && Array.isArray(definition.and)) {
    for (let i = definition.and.length - 1; i >= 0; i--) {
      conditionEach(definition.and[i], callback, () => {
        definition.and.splice(i, 1)
      })
    }
  }

  // Process 'or' conditions
  if (definition.or && Array.isArray(definition.or)) {
    for (let i = definition.or.length - 1; i >= 0; i--) {
      conditionEach(definition.or[i], callback, () => {
        definition.or.splice(i, 1)
      })
    }
  }
}

export const generateCombinationsForSegmentGroup = async (
  segmentGroup: Dimension
) => {
  const { generateSyncValues } = useClaudeApi()

  const segmentNames = segmentGroup.segments.map((s) =>
    stripHtml(segmentName(s))
  )

  const combinations = await generateSyncValues(segmentNames)

  segmentGroup.segments.forEach((segment) => {
    const foundCombination = combinations.syncValues.find(
      (c) => c.segmentName === stripHtml(segmentName(segment))
    )

    if (foundCombination) {
      autoSyncLogicDefinitionForSegment(segment).value =
        foundCombination.syncValue
    }
  })
}

export const createHybridSegmentGroup = async (segmentGroups: Dimension[]) => {
  const { generateSegmentGroupCombinations } = useClaudeApi()
  const segmentGroupStore = useSegmentGroupStore()
  const segmentGroupName = segmentGroups.map((sg) => sg.name).join(' *** ')
  const combinations = segmentCombinationsPossible(segmentGroups)

  let result = {
    name: segmentGroupName,
    segments: combinations.map((c) => ({
      name: c.segments
        .map((s) => segmentGroupStore.segmentForSegmentId(s.segmentId).name)
        .join(' *** '),
      mappings: c.segments.map((s) => [
        segmentGroupStore.segmentGroupForSegmentId(s.segmentId).id,
        s.segmentId
      ])
    }))
  }

  try {
    result = await generateSegmentGroupCombinations(result)
  } catch (e) {
    console.error(e)
  }

  // create a segment group for the result
  const segmentGroup = segmentGroupStore.create({
    type: 'segmentgroup',
    segmentGroup: {
      name: result.name
    }
  })

  result.segments.forEach((s) => {
    const segment = createSegment(segmentGroup, s.name)
    const signal = signalForSegment(segmentGroup, segment)
    if (signal) {
      const and = { and: [] }
      signal.definition.or.push(and)
      s.mappings.forEach((m) => {
        and.and.push({
          $type: m[0],
          $source: 'segments',
          operator: 'include any',
          segmentId: [m[1]]
        })
      })
    }
  })

  return segmentGroup
}
