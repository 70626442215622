import {
  BriefcaseIcon as DashboardIcon,
  SignalIcon as FeedIcon,
  ChatBubbleBottomCenterIcon as QuestionIcon,
  LinkIcon as IntegrationIcon,
  InboxIcon as FormIcon,
  FunnelIcon as FunnelIcon,
  UserCircleIcon as SegmentIcon,
  GiftIcon as CtaIcon,
  PuzzlePieceIcon as WidgetIcon,
  DocumentIcon as PagesIcon,
  WindowIcon as PersonalizationIcon,
  AtSymbolIcon as EmailIcon,
  BoltIcon as GoalIcon,
  ChartBarIcon as FunnelAnalysisIcon,
  BookOpenIcon as TemplatesIcon,
  ChatBubbleLeftRightIcon as SupportIcon,
  PresentationChartLineIcon as TestingIcon,
  FingerPrintIcon as AttributionIcon, CheckBadgeIcon as ChecklistIcon
} from '@heroicons/vue/24/outline'

const generateNavigation = computed(() => {
  const integrationStore = useIntegrationStore()
  const { integratedPartner } = storeToRefs(integrationStore)

  const onboardingStore = useOnboardingStore()
  const { isOnboarded } = storeToRefs(onboardingStore)

  const localDevelopment = import.meta.env.VITE_APP_ENV === 'development'

  return [
    isOnboarded.value ? {
      name: 'Dashboard',
      to: { name: ROUTES.DASHBOARD },
      icon: DashboardIcon
    } : { name: 'Setup Checklist', to: { name: ROUTES.DASHBOARD }, icon: ChecklistIcon },
    {
      name: 'Live Feed',
      to: { name: ROUTES.FEED },
      icon: FeedIcon
    },
    // {
    //   name: 'Templates',
    //   to: { name: ROUTES.TEMPLATES },
    //   icon: shallowRef(TemplatesIcon)
    // },
    {
      name: 'Enrich',
      type: 'divider'
    },
    {
      name: 'Segment Groups',
      to: { name: ROUTES.SEGMENTGROUPS },
      icon: SegmentIcon
    },
    {
      name: 'Questions',
      to: { name: ROUTES.QUESTIONS },
      icon: QuestionIcon
    },
    {
      name: 'Attribution',
      to: { name: ROUTES.ATTRIBUTION },
      icon: AttributionIcon
    },
    {
      name: 'Integration',
      to: { name: ROUTES.INTEGRATIONS },
      icon: IntegrationIcon,
      aside: integratedPartner.value ? `<img src="${integratedPartner.value.logo}" class="h-3.5 max-w-[70px] opacity-50 grayscale dark:invert" />` : ''
    },
    {
      name: 'Convert',
      type: 'divider'
    },
    {
      name: 'Flows',
      to: { name: ROUTES.OFFERFUNNELS },
      icon: FunnelIcon
    },
    {
      name: 'Widgets',
      to: { name: ROUTES.WIDGETS },
      icon: WidgetIcon
    },
    {
      name: 'Pages',
      to: { name: ROUTES.METRICS },
      icon: PagesIcon,
      comingsoon: true
    },
    {
      name: 'Forms',
      to: { name: ROUTES.FORMS },
      icon: FormIcon
    },
    {
      name: 'Offers & Promos',
      to: { name: ROUTES.OFFERS },
      icon: CtaIcon
    },
    {
      name: 'Personalization',
      type: 'divider'
    },
    {
      name: 'Emails',
      to: { name: ROUTES.EMAILS },
      icon: EmailIcon,
      beta: false
    },
    {
      name: 'Website',
      to: { name: ROUTES.CAMPAIGNS },
      icon: PersonalizationIcon,
      permission: 'personalization-campaign'
    },
    {
      name: 'Conversion Goals',
      to: { name: ROUTES.GOALS },
      icon: GoalIcon,
      permission: 'personalization-campaign'
    },
    // {
    //   name: 'Phrases',
    //   to: { name: ROUTES.GOALS },
    //   icon: shallowRef(PhraseIcon)
    // },
    { name: 'Optimize', type: 'divider' },
    {
      name: 'Audience Insights',
      to: { name: ROUTES.INSIGHTS },
      icon: TemplatesIcon
    },
    {
      name: 'A/B Testing',
      href: '#',
      icon: TestingIcon,
      comingsoon: true,
      permission: 'testing'
    },
    {
      name: 'Funnel Analysis',
      href: '#',
      icon: FunnelAnalysisIcon,
      comingsoon: true,
      permission: 'testing'
    },
    {
      name: 'Review Session',
      href: '#',
      icon: SupportIcon,
      comingsoon: true,
      permission: 'review'
    }
  ]
})

const generateUserNavigation = () => {
  const router = useRouter()
  const route = useRoute()
  const authStore = useAuthStore()
  return [
    {
      name: 'Your profile',
      action: () => {
        router.push({ name: ROUTES.PROFILE, query: { tab: 'profile' } })
      }
    },
    {
      name: 'Projects',
      action: () =>
        router.push({ name: ROUTES.PROFILE, query: { tab: 'projects' } })
    },
    {
      name: 'Billing',
      action: () =>
        router.push({ name: ROUTES.PROFILE, query: { tab: 'billing' } })
    },
    { name: 'Sign out', action: () => (window.location.href = '/logout') }
  ]
}

export { generateNavigation, generateUserNavigation }
