<script setup lang="ts">
import { VueQueryDevtools } from '@tanstack/vue-query-devtools'
import { useConfirm } from 'primevue/useconfirm'
import { useToast } from 'primevue/usetoast'

usePostHogProvider()

useDark()
const toast = useToast()
const confirm = useConfirm()
provide('confirm', confirm)
provide('toast', toast)

const notificationStore = useNotificationStore()

watch(notificationStore.notifications, (newNotifications) => {
  newNotifications.forEach((notification) => {
    toast.add({
      severity: notification.type,
      summary: notification.message,
      life: notification.sticky ? undefined : notification.life || 3000
    })
    notificationStore.removeNotification(notification)
  })
})
</script>

<template>
  <div>
    <Toast />
    <ConfirmDialog />
    <DefaultLayout />
    <VueQueryDevtools v-if="false" />
  </div>
</template>
