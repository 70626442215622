<template>
  <div v-if="user && config" class="relative">
    <Sidebar></Sidebar>
    <div
      class="min-h-screen overflow-hidden bg-slate-50 dark:bg-slate-900"
      :class="[!route.meta?.fullScreen ? 'lg:pl-72' : 'lg:pl-16']">
      <div
        class="space-y-12"
        :class="[
          route.meta?.fullScreen
            ? ''
            : 'mx-auto max-w-7xl px-4 py-10 sm:px-6 lg:px-8'
        ]">
        <router-view></router-view>
      </div>
    </div>
  </div>
  <div v-else-if="isError" class="flex h-screen items-center justify-center">
    <div class="text-center">
      <h1 class="text-2xl font-bold">
        There's an error loading your team's configuration.
      </h1>
      <p class="text-gray-500">
        Please contact support at support@rightmessage.com
      </p>
      <div class="mt-4">
        <TeamDropdown class="w-3xl" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const route = useRoute()

const authStore = useAuthStore()
const { user } = storeToRefs(authStore)
const configStore = useConfigStore()
const { config, currentTeamPid, isError } = storeToRefs(configStore)
const { canAccess } = usePermissionStore()
provide('canAccess', canAccess)

useTeamStore()

const posthog = usePostHog()

watch(
  user,
  () => {
    if (user.value) {
      posthog?.identify(user.value.email, user.value)
    }
  },
  { immediate: true }
)

watch(
  () => route.params.teamPid,
  () => {
    currentTeamPid.value = parseInt(route.params.teamPid as string)
  },
  { immediate: true }
)
</script>
