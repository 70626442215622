import { createRouter, createWebHistory } from 'vue-router'
import { useToast } from 'primevue/usetoast'
import routes from './routes'
import middlewarePipeline from './middleware/middlewarePipeline'
import ensureResourceExists from './middleware/ensureResourceExists'
import { ref } from 'vue'

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(_: any, __: any, ___: any) {
    // always scroll to top
    return { top: 0 }
  }
})

// Track last notification time
const lastNotificationTime = ref(0)

router.beforeEach((to, from, next) => {
  const middleware = to.meta.middleware
  const context = { to, from, next }

  const toast = useToast()
  toast.removeAllGroups()

  if (!middleware) {
    return next()
  }

  middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1)
  })
})

router.afterEach((to, from, failure) => {
  const configStore = useConfigStore()
  const { isDirty } = storeToRefs(configStore)

  const notificationStore = useNotificationStore()
  const currentTime = Date.now()
  const tenMinutesInMs = 10 * 60 * 1000

  if (
    isDirty.value &&
    currentTime - lastNotificationTime.value > tenMinutesInMs
  ) {
    notificationStore.add({
      type: 'warn',
      message: `It looks like you've made a few changes. Make sure to publish to your website when you're ready for them to go live.`,
      life: 6000
    })
    lastNotificationTime.value = currentTime
    isDirty.value = false
  }
  if (!failure) {
    const posthog = usePostHog()
    posthog?.capture('$pageleave', {
      $current_url: window.location.host + from.fullPath,
      path: from.fullPath
    })
    posthog?.capture('$pageview', { path: to.fullPath })
  }
})

export default router
