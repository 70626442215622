import { ROUTES } from '@/router/routeNames'

export default [
  {
    path: 'campaigns',
    children: [
      {
        name: ROUTES.CAMPAIGNS,
        path: '',
        component: () => import('./pages/CampaignsIndex.vue'),
        meta: {
          title: 'Website Personalization Campaigns',
          description: `Personalize any content on your website based on the segmentation data that RightMessage helps collect, or from data that already exists for a known contact in your email platform.`,
          helpDoc: 'personalizationCampaigns.default'
        }
      },
      {
        name: ROUTES.CREATECAMPAIGN,
        path: 'new',
        component: () => import('./pages/CampaignCreate.vue')
      },
      {
        name: ROUTES.CAMPAIGN,
        path: ':campaignId',
        component: () => import('./pages/CampaignShow.vue'),
        props: true
      }
    ]
  },
  {
    path: 'emails',
    children: [
      {
        name: ROUTES.EMAILS,
        path: '',
        component: () => import('./pages/EmailsIndex.vue'),
        meta: {
          title: 'Personalized Emails',
          description: `Using the data RightMessage helps you collect, it's now easier than ever to create highly personalized emails.`
        }
      },
      {
        name: ROUTES.CREATEEMAIL,
        path: 'new',
        component: () => import('./pages/EmailCreate.vue')
      },
      {
        name: ROUTES.EMAIL,
        path: ':emailId',
        component: () => import('./pages/EmailShow.vue'),
        props: true
      }
    ]
  },
  {
    path: 'goals',
    children: [
      {
        name: ROUTES.GOALS,
        path: '',
        component: () => import('./pages/GoalsIndex.vue'),
        meta: {
          title: 'Conversion Goals',
          description: `Conversion goals can be associated with a campaign, and are used to indicate the success of a campaign. When one or more goals are attached to a personalization campaign, we'll automatically run an A/B test on the campaign to determine what impact personalization is having.`
        }
      },
      {
        name: ROUTES.CREATEGOAL,
        path: 'new',
        component: () => import('./pages/GoalCreate.vue')
      }
    ]
  }
]
