import { defineStore } from 'pinia'
import { ref } from 'vue'
import api from '@/services/axiosApi'

export const useAiStore = defineStore('ai', () => {
  const isGenerating = ref(false)
  const route = useRoute()

  const setIsGenerating = (value: boolean) => {
    isGenerating.value = value
  }

  const crawlPage = async (url: string) => {
    const { data } = await api.get(
      `/api/${route.params.teamPid}/ai/crawl?url=${url}`
    )
    return data
  }

  return {
    isGenerating,
    setIsGenerating,
    crawlPage
  }
})
