import { defineStore } from 'pinia'
import { computed } from 'vue'
import { useConfigStore } from '@/stores/configStore'

export const useOnboardingStore = defineStore('onboarding', () => {
  const configStore = useConfigStore()
  const { fullResponse } = storeToRefs(configStore)

  const integrationStore = useIntegrationStore()
  const { integratedPartner } = storeToRefs(integrationStore)
  const hasIntegrated = computed(() => !!integratedPartner.value)

  const reportStore = useReportStore()
  const { dataLastReceived } = storeToRefs(reportStore)
  const hasAddedScript = computed(() => {
    if (!dataLastReceived.value) return true
    return dataLastReceived.value?.last_update !== null
  })

  const flowStore = useFlowStore()
  const { flows } = storeToRefs(flowStore)
  const hasCreatedFlow = computed(
    () =>
      flows.value.filter(
        (f) => visibleThingsInFlow(f.funnelDefinition).length > 0
      ).length > 0
  )

  const widgetStore = useWidgetStore()
  const { widgets } = storeToRefs(widgetStore)
  const hasCreatedWidget = computed(
    () =>
      widgets.value.filter((widget) => widget.contentId && widget.isEnabled)
        .length > 0
  )

  const { currentTeam } = storeToRefs(configStore)
  const hasPublished = computed(
    () => !!currentTeam.value?.last_published_at || fullResponse.value?.stable
  )

  const isOnboarded = computed(() => {
    return !!(
      hasAddedScript.value &&
      hasCreatedFlow.value &&
      hasCreatedWidget.value &&
      hasPublished.value
    )
  })

  return {
    isOnboarded,
    hasIntegrated,
    hasAddedScript,
    hasCreatedFlow,
    hasCreatedWidget,
    hasPublished
  }
})
