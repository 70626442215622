import axios from 'axios'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css' // Import the CSS for styling
import { useRouter } from 'vue-router'

export const noAuthApi = axios.create({
  baseURL: import.meta.env.VITE_APP_BACKEND_HOST,
  withCredentials: false,
  withXSRFToken: false,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
})

const api = axios.create({
  baseURL: import.meta.env.VITE_APP_BACKEND_HOST,
  withCredentials: true,
  withXSRFToken: true,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
})

noAuthApi.interceptors.request.use((config) => {
  NProgress.start()
  return config
})

noAuthApi.interceptors.response.use(
  (response) => {
    NProgress.done()
    return response
  },
  (error) => {
    NProgress.done()
    return Promise.reject(error)
  }
)

// Request interceptor
api.interceptors.request.use((config) => {
  NProgress.start()
  return config
})

// Response interceptor
api.interceptors.response.use(
  (response) => {
    NProgress.done()
    return response
  },
  (error) => {
    NProgress.done()

    // Check for 401 Unauthorized error
    if (error.response?.status === 401) {
      // Import router from your router configuration
      const router = useRouter()
      router.push('/logout')
    }

    return Promise.reject(error)
  }
)

export default api
