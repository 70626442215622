import { defineStore } from 'pinia'
import { computed } from 'vue'
import { useConfigStore } from '@/stores/configStore'
import { makeId, idPrefixes } from '@/services/uuid'
import { campaignSchema, Campaign } from '@/types/config'

export const useCampaignStore = defineStore('campaign', () => {
  const configStore = useConfigStore()
  const { config } = storeToRefs(configStore)

  const campaigns = computed(() =>
    (config.value?.campaigns || []).sort((a, b) => a.name.localeCompare(b.name))
  )
  const getById = (id: string) =>
    campaigns.value.find((campaign) => campaign.id === id)

  const create = (campaign: Partial<Campaign>) => {
    if (!config.value) return

    const newCampaign = campaignSchema.parse({
      id: makeId(idPrefixes.CAMPAIGN),
      name: `New Campaign ${campaigns.value.length + 1}`,
      isActive: true,
      dimension_id: null,
      recipe_code: null,
      goal_ids: [],
      condition: true,
      actions: [],
      ...campaign
    }) as Campaign

    // Extract domain from base_url and add to domainNames if not exists
    if (campaign.base_url) {
      const domainNameStore = useDomainNameStore()
      const url = new URL(campaign.base_url)
      const hostname = url.hostname

      if (!domainNameStore.domains.includes(hostname)) {
        domainNameStore.create({ name: hostname })
      }
    }

    config.value.campaigns.push(newCampaign)
    return newCampaign
  }

  const destroy = (campaign: Campaign) => {
    const foundIndex = campaigns.value.findIndex((c) => c.id == campaign.id)
    if (foundIndex === -1) return

    campaigns.value.splice(foundIndex, 1)
  }

  const duplicate = (campaign: Campaign) => {
    const newCampaign = {
      ...klona(campaign),
      id: makeId(idPrefixes.CAMPAIGN),
      name: `${campaign.name} (Copy)`
    }

    campaigns.value.push(newCampaign)

    return newCampaign
  }

  return {
    campaigns,
    getById,
    create,
    destroy,
    duplicate
  }
})
