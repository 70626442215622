import { defineStore } from 'pinia'
import { computed } from 'vue'
import { useConfigStore } from '@/stores/configStore'
import { questionSchema, Question, Dimension } from '@/types/config'

export const useQuestionStore = defineStore('question', () => {
  const configStore = useConfigStore()
  const { config, isLoading, isError } = storeToRefs(configStore)

  const templateStore = useTemplateStore()
  const { questions: tmpQuestions } = storeToRefs(templateStore)

  const questions = computed<Question[]>(() => {
    const realQuestions = config.value?.rightAsk?.questions || []
    const templateQuestions = tmpQuestions.value || []
    return [...realQuestions, ...templateQuestions]
  })

  const getById = (id: string): Question | undefined =>
    questions.value.find((question: Question) => question.id === id)
  const getBySegmentGroupId = (id: string): Question | undefined =>
    questions.value.find((question: Question) => question.dimensionId === id)

  const destroy = (question: Question) => {
    const foundIndex = config.value?.rightAsk?.questions.findIndex(
      (q) => q.id === question.id
    )
    if (foundIndex === -1) return // this should really never happen

    config.value?.rightAsk?.questions?.splice(foundIndex, 1)
  }

  return {
    questions,
    getById,
    getBySegmentGroupId,
    destroy,
    isLoading,
    isError
  }
})
