import { ROUTES } from '@/router/routeNames'

export default [
  {
    path: 'widgets',
    children: [
      {
        name: ROUTES.WIDGETS,
        path: '',
        component: () => import('./pages/WidgetsIndex.vue'),
        meta: {
          title: 'Widgets',
          description: `Widgets are how your Flows are displayed across your website. They determine the look and feel of the questions and offers that you include in the Flow they're attached to.`
        }
      },
      {
        name: ROUTES.CREATEWIDGET,
        path: 'new',
        component: () => import('./pages/WidgetCreate.vue')
      },
      {
        name: ROUTES.WIDGET,
        path: ':widgetId',
        component: () => import('./pages/WidgetShow.vue'),
        props: true
      }
    ]
  }
]
