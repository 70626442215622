import api from '@/services/axiosApi'
import { Offer } from '@/types/config'

export const isForm = (offer: Offer) => {
  if (!offer) return false
  return offer.triggeredAction?.type !== 'link'
}

export const offerLink = (offer: Offer) => {
  const isCta = !isForm(offer)
  return {
    name: isCta ? ROUTES.OFFER : ROUTES.FORM,
    params: {
      offerId: offer.id
    }
  }
}

export const offerUsedBy = (offer: Offer) => {
  const flowStore = useFlowStore()
  return flowStore.flows.filter((flow) => {
    let found = false
    funnelDefinitionEach(flow.funnelDefinition, (node) => {
      if (node.type === 'offer' && node.offerId === offer.id) {
        found = true
      }
    })
    return found
  })
}

export const offerIsInUse = (offer: Offer) => {
  return offerUsedBy(offer).length > 0
}

export const offerCanBeSubmitted = (offer: Offer) => {
  if (offer.triggeredAction?.type === 'link') return true
  if (offer.triggeredAction.type === 'form')
    return offer.triggeredAction.settings.formIds?.length > 0
  if (offer.triggeredAction.type === 'list')
    return offer.triggeredAction.settings.listIds?.length > 0
  if (offer.triggeredAction.type === 'tag')
    return offer.triggeredAction.settings.tagIds?.length > 0
  if (offer.triggeredAction.type === 'event')
    return offer.triggeredAction.settings.eventIds?.length > 0
  return false
}

export interface OfferStats {
  visitorsExposed: number
  visitorsConverted: number
  conversionRate: number
  timeSeries: Array<{ visitorsConverted: number }>
  pages?: Array<{
    url: string
    exposureCount: number
    conversionCount: number
    breakdown: Array<{ conversionCount: number }>
  }>
  variations?: {
    total: number
    combinations: Array<{
      title: string
      description: string
      buttonText: string
      segments: Array<{
        segmentGroupId: string
        segment: string | null
      }>
      metrics?: {
        exposures: number
        conversions: number
        conversionRate: number
      }
    }>
  }
}

// Extract all phrase IDs from a text string
const extractPhraseIds = (text: string): string[] => {
  if (!text) return []
  const matches = text.match(/{{ phrases\.(\w+) }}/g) || []
  return matches.map((match) => match.match(/{{ phrases\.(\w+) }}/)[1])
}

// Get all possible combinations of phrases
const generateCombinations = (segmentGroups: Record<string, Set<string>>) => {
  const groupEntries = Object.entries(segmentGroups)
  const groups = groupEntries.map(([id, set]) => ({
    id,
    segments: [...Array.from(set), null]
  }))

  function combine(index: number, current: any[]): any[] {
    if (index === groups.length) {
      return [current]
    }

    const result = []
    for (const segment of groups[index].segments) {
      result.push(
        ...combine(index + 1, [
          ...current,
          {
            segmentGroupId: groups[index].id,
            segment: segment
          }
        ])
      )
    }
    return result
  }

  return combine(0, [])
}

// Generate all possible variations of an offer
export const generateOfferVariations = (
  offer: Offer
): OfferStats['variations'] => {
  const phraseStore = usePhraseStore()
  const { phrases } = storeToRefs(phraseStore)
  const segmentGroupStore = useSegmentGroupStore()
  const { segmentGroups } = storeToRefs(segmentGroupStore)

  // Extract phrase IDs from all offer content
  const titlePhrases = extractPhraseIds(offer.parts.title)
  const descriptionPhrases = extractPhraseIds(offer.parts.description)
  const buttonTextPhrases = extractPhraseIds(offer.parts.buttonText)

  // Get all unique phrase IDs
  const allPhraseIds = [
    ...new Set([...titlePhrases, ...descriptionPhrases, ...buttonTextPhrases])
  ]

  // Get included phrases and their segment groups
  const includedPhrases = phrases.value.filter((p) =>
    allPhraseIds.includes(p.id)
  )
  const includedSegmentGroups = includedPhrases
    .map((phrase) =>
      segmentGroups.value.find((sg) => sg.id === phrase.segmentGroupId)
    )
    .filter(Boolean)

  // Create groupings for combinations
  const groupings = includedSegmentGroups.reduce(
    (acc, sg) => {
      if (!sg) return acc
      if (!acc[sg.id]) {
        acc[sg.id] = new Set()
      }
      return acc
    },
    {} as Record<string, Set<string>>
  )

  // Add segments to groupings
  includedPhrases.forEach((phrase) => {
    Object.keys(phrase.map).forEach((key) => {
      if (!groupings[phrase.segmentGroupId]) {
        groupings[phrase.segmentGroupId] = new Set()
      }
      groupings[phrase.segmentGroupId].add(key)
    })
  })

  // Generate all possible combinations
  const combinations = generateCombinations(groupings)

  // Generate variations for each combination
  const variations = combinations.map((combination) => {
    const replacePhrases = (text: string) => {
      return text.replace(/{{ phrases\.(\w+) }}/g, (match, id) => {
        const phrase = phrases.value.find((p) => p.id === id)
        if (!phrase) return match

        const mapping =
          phrase.map[
            combination.find((c) => c.segmentGroupId === phrase.segmentGroupId)
              ?.segment
          ]

        return mapping || phrase.fallbackText
      })
    }

    return {
      title: replacePhrases(offer.parts.title || ''),
      description: replacePhrases(offer.parts.description || ''),
      buttonText: replacePhrases(offer.parts.buttonText || ''),
      segments: combination
    }
  })

  return {
    total: variations.length,
    combinations: variations
  }
}

export const offerStats = (
  offer: Offer,
  data: any,
  source: 'core' | 'api' = 'core'
): OfferStats | null => {
  if (source === 'core') {
    const offerData = data?.offersData?.offerBreakdowns?.find(
      (o: { offerId: string }) => o.offerId === offer.id
    )

    if (!offerData) return null

    const variations = generateOfferVariations(offer)

    // If we have performance data for variations, merge it in
    if (data?.variationsData?.[offer.id]) {
      variations?.combinations.forEach((combination, index) => {
        const metrics = data.variationsData[offer.id][index]
        if (metrics) {
          combination.metrics = {
            exposures: metrics.exposures,
            conversions: metrics.conversions,
            conversionRate: metrics.conversionRate
          }
        }
      })
    }

    return {
      visitorsExposed: offerData.visitorsExposed,
      visitorsConverted: offerData.visitorsConverted,
      conversionRate: Number(
        (
          (offerData.visitorsConverted / offerData.visitorsExposed) *
          100
        ).toFixed(2)
      ),
      timeSeries: offerData.timeSeries,
      variations
    }
  } else {
    // API data format
    if (!data) return null

    const variations = generateOfferVariations(offer)

    // If we have performance data for variations, merge it in
    if (data.combinations) {
      variations?.combinations.forEach((combination, index) => {
        const metrics = data.combinations.find(
          (c: any) => c.key === String(index)
        )
        if (metrics) {
          combination.metrics = {
            exposures: metrics.exposures,
            conversions: metrics.conversions,
            conversionRate: metrics.conversionRate
          }
        }
      })
    }

    return {
      visitorsExposed: data.exposureCount,
      visitorsConverted: data.conversionCount,
      conversionRate: Number(
        ((data.conversionCount / data.exposureCount) * 100).toFixed(2)
      ),
      timeSeries: data.breakdown || [],
      pages: data.pages,
      variations
    }
  }
}

export const fetchOfferPerformance = async (
  offerIds: string[],
  start: string,
  end: string,
  timezone: string,
  combinations?: Array<Array<string | null>>
) => {
  const { currentTeamPid } = useConfigStore()
  const response = await api.post(`/api/${currentTeamPid}/metrics/offerStats`, {
    offerIds,
    start,
    end,
    timezone,
    combinations
  })
  return response.data
}
