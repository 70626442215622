<template>
  <Select
    class="w-[10rem] flex-grow"
    :options="teams"
    @change="selectTeam"
    optionValue="pid"
    optionLabel="name"
    :placeholder="currentTeam?.name || 'Select a team'">
    <template #value>
      <div class="flex items-center space-x-1.5">
        <div class="w-[100px] truncate text-sm text-black dark:text-white/80">
          <template v-if="!currentTeam">
            <span class="opacity-50">Select a project</span>
          </template>
          <template v-else>
            <template v-if="!doesOwnCurrentTeam">
              {{ currentTeam.name }}
            </template>
            <template v-else>
              <template v-if="currentTeam.name">
                {{ currentTeam.name }}
              </template>
              <template v-else>
                <span class="opacity-50">New team</span>
              </template>
            </template>
          </template>
        </div>
      </div>
    </template>
    <template #option="slotProps">
      <div class="block text-sm">
        <div class="flex items-center space-x-1.5">
          <img
            :src="`https://img.logo.dev/${slotProps.option.website}?token=pk_cZiWDpV1RAC_ml3vv_hAww&size=200`"
            class="h-6 w-6 rounded-full" />
          <span class="ml-2 text-base font-normal">
            {{ slotProps.option.name }}
          </span>
          <span class="opacity-30">/</span>
          <span class="opacity-50">
            {{ slotProps.option.website }}
          </span>
        </div>
      </div>
    </template>
  </Select>
</template>

<script setup lang="ts">
const router = useRouter()

const teamStore = useTeamStore()
const { teams, doesOwnCurrentTeam } = storeToRefs(teamStore)

const configStore = useConfigStore()
const { currentTeam } = storeToRefs(configStore)

const selectTeam = ({ value }: { value: number }): void => {
  router.push({
    name: ROUTES.DASHBOARD,
    params: { teamPid: value }
  })
}
</script>
