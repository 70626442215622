import api from '@/services/axiosApi'

export default {
  native_fields: {
    queryFn: (teamPid: number) => {
      const { integratedPartner } = useIntegrationStore()

      if (integratedPartner?.espFeatureSupport?.customField?.unstructured) {
        return []
      }

      return api
        .get(
          `/api/${teamPid}/integrations/${integratedPartner?.name}/native_fields`
        )
        .then((response) => response.data.native_fields)
    }
  },
  custom_fields: {
    queryFn: (teamPid: number) => {
      const { integratedPartner, hasNewIntegration } = useIntegrationStore()

      if (integratedPartner?.espFeatureSupport?.customField?.unstructured) {
        return []
      }

      if (hasNewIntegration) {
        return api
          .get(
            `/api/${teamPid}/connections/${integratedPartner?.name}/custom_fields`
          )
          .then((response) => {
            return response.data.customFields
          })
      }

      return api
        .get(
          `/api/${teamPid}/integrations/${integratedPartner?.name}/custom_fields`
        )
        .then((response) => response.data.custom_fields)
    }
  },
  lists: {
    queryFn: (teamPid: number) => {
      const { integratedPartner, hasNewIntegration } = useIntegrationStore()

      if (hasNewIntegration) {
        return api
          .get(`/api/${teamPid}/connections/${integratedPartner?.name}/lists`)
          .then((response) => {
            return response.data.lists
          })
      }

      return api
        .get(`/api/${teamPid}/integrations/${integratedPartner?.name}/lists`)
        .then((response) => response.data.lists)
    }
  },
  tags: {
    queryFn: (teamPid: number) => {
      const { integratedPartner } = useIntegrationStore()
      return api
        .get(`/api/${teamPid}/integrations/${integratedPartner?.name}/tags`)
        .then((response) => response.data.tags)
    }
  },
  forms: {
    queryFn: (teamPid: number) => {
      const { integratedPartner } = useIntegrationStore()
      return api
        .get(`/api/${teamPid}/integrations/${integratedPartner?.name}/forms`)
        .then((response) => response.data.forms)
    }
  }
}
