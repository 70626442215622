import { QueryClient, focusManager } from '@tanstack/vue-query'
import all from '@/modules/integration/data/all'

// Create a client instance
const queryClient = new QueryClient({})

focusManager.setEventListener((handleFocus) => {
  // Listen to visibilitychange
  if (typeof window !== 'undefined' && window.addEventListener) {
    const visibilitychangeHandler = () => {
      if (document.visibilityState === 'visible') {
        queryClient.invalidateQueries({ queryKey: ['integrations'] })
      }
    }
    window.addEventListener('visibilitychange', visibilitychangeHandler, false)
    return () => {
      // Be sure to unsubscribe if a new handler is set
      window.removeEventListener('visibilitychange', visibilitychangeHandler)
    }
  }
})

export const getIntegrationData = async (scope: string, key: string) => {
  const { currentTeamPid } = useConfigStore()
  const { integratedPartner } = useIntegrationStore()
  const cacheKey = ['integrations', currentTeamPid, scope, key]

  if (scope === 'partner' && !integratedPartner?.name) {
    return computed(() => [])
  }

  if (queryClient.getQueryData(cacheKey)) {
    return computed(() => queryClient.getQueryData(cacheKey))
  }

  const result = await queryClient.fetchQuery({
    queryKey: cacheKey,
    queryFn: () => all[scope][key].queryFn(currentTeamPid)
  })

  const data = computed(() => {
    return result || []
  })

  return data
}
