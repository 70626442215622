import { provide, inject } from 'vue'
import posthog from 'posthog-js'

const PostHogSymbol = Symbol('PostHog')

export function usePostHogProvider() {
  if (import.meta.env.VITE_APP_ENV === 'development') return
  const posthogInstance = posthog.init(import.meta.env.VITE_POSTHOG_API_KEY, {
    api_host: import.meta.env.VITE_POSTHOG_API_HOST,
    person_profiles: 'identified_only',
    session_recording: {
      maskAllInputs: false,
      maskInputOptions: {
        password: true
      }
    }
  })

  console.log('posthogInstance', posthogInstance)

  provide(PostHogSymbol, posthogInstance)

  return posthogInstance
}

export function usePostHog() {
  const posthogInstance = inject(PostHogSymbol)

  return posthogInstance
}
