<template>
  <div>
    <label
      v-if="label !== false"
      class="block font-bold leading-6 text-primary-900 dark:text-white/80"
      :class="[
        size === 'xl' ? 'text-xl' : size === 'lg' ? 'text-lg' : 'text-sm'
      ]">
      {{ label }}
    </label>
    <div
      :class="[
        !label ? '' : size === 'xl' ? 'mt-8' : size === 'lg' ? 'mt-6' : 'mt-2'
      ]">
      <slot />
    </div>
    <div
      class="mt-2 text-sm text-primary-500/80 dark:text-white/40"
      v-if="$slots.description">
      <slot name="description" />
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  label: [String, Boolean],
  size: { type: String, default: 'md' }
})
const { label, size } = toRefs(props)
</script>
