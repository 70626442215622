import { defineStore } from 'pinia'
import { useQuery, useMutation } from '@tanstack/vue-query'
import { useAuthStore } from './authStore'
import { storeToRefs } from 'pinia'
import api from '@/services/axiosApi'
import * as jsondiffpatch from 'jsondiffpatch'
import { Team } from '@/types/team'

export const useTeamStore = defineStore('team', () => {
  const doesOwnCurrentTeam = ref(false) as Ref<boolean>
  const authStore = useAuthStore()
  const configStore = useConfigStore()
  const router = useRouter()
  const { currentTeam, currentTeamPid } = storeToRefs(configStore)
  const { user } = storeToRefs(authStore)

  const teamsQuery = useQuery({
    queryKey: ['teams'],
    queryFn: () => api.get('/api/teams').then((res) => res.data),
    enabled: computed(() => !!user.value)
  })

  const teams = toRef(teamsQuery, 'data')

  watch(
    () => currentTeam.value,
    (is, was) => {
      if (was) {
        const diff = jsondiffpatch.diff(was, is)
        if (diff) {
          const diffKeys = Object.keys(diff)
          const ignoredKeys = ['last_seen_at', 'owner', 'updated_at']

          // Check if all diff keys are in the ignored list
          const shouldIgnore = diffKeys.every((key) =>
            ignoredKeys.includes(key)
          )

          if (shouldIgnore) {
            return // Skip debounce if only ignored fields changed
          }
        }
        debouncedUpdateTeam(is)
      }
    },
    { deep: true }
  )

  const updateTeamMutation = useMutation({
    mutationFn: (variables: { team: Team }) =>
      api.put(`/api/${variables.team.pid}/team`, {
        ...variables.team
      })
  })

  const deleteTeamMutation = useMutation({
    mutationFn: (variables: { team: Team }) =>
      api.delete(`/api/teams/${variables.team.pid}`),
    onSuccess: () => {
      teamsQuery.refetch()
      configStore.configQuery.refetch()
      authStore.getUser(true)
    }
  })

  const removeTeamMemberMutation = useMutation({
    mutationFn: (variables: { memberId: number; isInvited: boolean }) => {
      return api.delete(
        `api/${currentTeamPid.value}/team/members/${variables.memberId}`,
        {
          params: {
            invited: variables.isInvited ? '1' : '0'
          }
        }
      )
    },
    onSuccess: (_) => {
      configStore.configQuery.refetch()
    }
  })

  const addTeamMemberMutation = useMutation({
    mutationFn: (memberData: { email: string; role: string }) =>
      api.post(`/api/${currentTeamPid.value}/team/members`, memberData),
    onSuccess: (_) => {
      configStore.configQuery.refetch()
    }
  })

  const deleteTeam = (team: Team) => {
    deleteTeamMutation.mutate({ team })
  }
  const removeTeamMember = () => {
    const { isError, error, isPending, mutate, mutateAsync } =
      removeTeamMemberMutation
    return { isPending, error, isError, mutate, mutateAsync }
  }
  const addTeamMember = () => {
    const { isError, error, isPending, mutate, mutateAsync } =
      addTeamMemberMutation
    return { isPending, error, isError, mutate, mutateAsync }
  }

  const debouncedUpdateTeam = useDebounceFn((newTeam: Team) => {
    updateTeamMutation.mutate({ team: newTeam })
  }, 2000)

  const create = (team: Team) => {
    api.post('/api/teams', { ...team }).then(async ({ data }) => {
      await teamsQuery.refetch()
      router.push({
        name: ROUTES.DASHBOARD,
        params: { teamPid: data.team.pid }
      })
    })
  }

  return {
    teams,
    currentTeam,
    doesOwnCurrentTeam,
    isSuccess: teamsQuery.isSuccess,
    deleteTeam,
    create,
    removeTeamMember,
    addTeamMember
  }
})
