import { defineStore } from 'pinia'

export const usePermissionStore = defineStore('permission', () => {
  const configStore = useConfigStore()
  const { owner, currentTeam } = storeToRefs(configStore)

  const canAccess = (permission: string) => {
    const accountPermissions = owner.value?.permissions || {}
    const rolePermissions = currentTeam.value?.permissions || {}
    return accountPermissions[permission] || rolePermissions[permission]
  }

  return {
    canAccess
  }
})
