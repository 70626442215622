export default function auth({ to, next, store }) {
  const authStore = useAuthStore()
  authStore
    .getUser()
    .then(() => {
      next()
    })
    .catch(() => {
      next({ name: ROUTES.LOGIN })
    })
}
