export default {
  root: {
    class: [
      //Flex
      'flex flex-col',

      //Shape
      'rounded shadow',

      //Color
      'bg-white dark:bg-slate-950/80 ring ring-1 ring-surface-900/10 dark:ring-surface-200/20',
      'text-brand-text dark:text-white'
    ]
  },
  body: {
    class: [
      //Flex
      'flex flex-col',
      'gap-4',

      'p-6'
    ]
  },
  caption: {
    class: [
      //Flex
      'flex flex-col',
      'gap-2'
    ]
  },
  title: {
    class: 'text-xl font-semibold mb-0'
  },
  subtitle: {
    class: [
      //Font
      'font-normal',

      //Spacing
      'mb-0',

      //Color
      'text-brand-placeholder'
    ]
  },
  content: {
    class: 'p-0'
  },
  footer: {
    class: 'p-0'
  }
}
