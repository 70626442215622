import api from '@/services/axiosApi'

export const AnalyticsEventTypes = Object.freeze({
  CAMPAIGN_CREATED: 'Campaign created',
  GOAL_CREATED: 'Goal created',
  TAG_CREATED: 'Tag created',
  SEGMENT_GROUP_CREATED: 'Segment group created',
  QUESTION_CREATED: 'Question created',
  RIGHT_ASK_QUESTION_CREATED: 'RightAsk Question created',
  FLOW_CREATED: 'Flow created',
  WIDGET_CREATED: 'Widget created',
  REGISTERED: 'Completed signup user details',
  LOGGED_IN: 'Logged in',
  PUBLISHED: 'Published snippet',
  INTEGRATION_CREATED: 'Integration created',
  ACCESSED_TEAM: 'Accessed team',

  VIEWED_DASHBOARD: 'Viewed dashboard',
  OFFER_CREATED: 'Offer created',
  FORM_CREATED: 'Form created'
})

export const recordAnalyticsEvent = (event: string, params = {}) => {
  const { user } = useAuthStore()
  const { currentTeamPid, currentTeam } = useConfigStore()
  if (!user) return
  if (
    user.is_admin &&
    !user.owned_teams.find((t) => String(t.pid) === String(currentTeamPid))
  )
    return

  return api.post('/api/analytics', {
    event,
    params: {
      ...params,
      teamPid: currentTeam?.pid,
      teamName: currentTeam?.name
    }
  })
}
