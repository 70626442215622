import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useSidebarStore = defineStore('sidebar', () => {
  const isExpanded = ref(false)

  function toggleSidebar() {
    isExpanded.value = !isExpanded.value
  }

  function expandSidebar() {
    isExpanded.value = true
  }

  function collapseSidebar() {
    isExpanded.value = false
  }

  return {
    isExpanded,
    toggleSidebar,
    expandSidebar,
    collapseSidebar
  }
})
