import { ROUTES } from '@/router/routeNames'

export default [
  {
    name: ROUTES.DASHBOARD_INDEX,
    path: '',
    beforeEnter: async (to, from, next) => {
      const router = useRouter()
      router.push({
        name: ROUTES.DASHBOARD,
        params: { teamPid: to.params.teamPid }
      })
      next()
    }
  },
  {
    name: ROUTES.DASHBOARD,
    path: 'dashboard',
    component: () => import('./pages/DashboardIndex.vue'),
    meta: {
      title: 'Dashboard'
    }
  },
  {
    name: ROUTES.FEED,
    path: 'feed',
    component: () => import('./pages/Feed.vue'),
    meta: {
      title: 'Live Feed',
      description: 'A live feed of events from your website visitors.'
    }
  }
]
