import { ROUTES } from '@/router/routeNames'

export default [
  {
    path: 'offers',
    children: [
      {
        name: ROUTES.OFFERS,
        path: '',
        component: () => import('@/modules/offer/pages/OffersIndex.vue'),
        meta: {
          title: 'Offers',
          description: `Offers are the things that you want to promote to your website visitors.`
        }
      },
      {
        name: ROUTES.OFFER,
        path: ':offerId',
        component: () => import('@/modules/offer/pages/OfferShow.vue'),
        props: true
      }
    ]
  },
  {
    path: 'forms',
    children: [
      {
        name: ROUTES.FORMS,
        path: '',
        component: () => import('@/modules/offer/pages/FormsIndex.vue'),
        meta: {
          title: 'Forms',
          description: `These are forms that you can use to turn your website visitors into leads.`
        }
      },
      {
        name: ROUTES.FORM,
        path: ':offerId',
        component: () => import('@/modules/offer/pages/OfferShow.vue'),
        props: true
      }
    ]
  }
]
