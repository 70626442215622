import { ROUTES } from '@/router/routeNames'

export default [
  {
    path: 'flows',
    children: [
      {
        name: ROUTES.OFFERFUNNELS,
        path: '',
        component: () => import('./pages/FlowsIndex.vue'),
        meta: {
          title: 'Flows',
          description: `Flows are powerful funnels that allow you to show the right offers, questions, and more to the right people.`
        }
      },
      {
        name: ROUTES.CREATEOFFERFUNNEL,
        path: 'new',
        component: () => import('./pages/FlowNew.vue'),
        meta: {
          title: 'New Flow'
        }
      },
      {
        name: ROUTES.OFFERFUNNEL,
        path: ':offerFunnelId',
        component: () => import('./pages/FlowShow.vue'),
        props: true,
        meta: { fullScreen: true },
        children: [
          {
            name: ROUTES.OFFERFUNNELNODE,
            path: ':uuid',
            component: () => import('./pages/NodeEdit.vue')
          }
        ]
      }
    ]
  }
]
