<template>
    <Dialog v-model:visible="visible" modal @update:visible="updateVisible" header="Save a snapshot of your config">
        <div class="w-2/3 mb-6 text-sm text-gray-600">A snapshot simply is saving your current config module so you can load it up at
            a
            later
            time, just in case you mistakenly messed up your config later in the future.</div>
        <div class="mb-8">
            <label for="snapshotDescription" class="text-sm font-semibold text-black">Snapshot Description</label>
            <Textarea class="block w-2/3 mt-2 placeholder:text-gray-500 placeholder:text-sm" id="snapshotDescription" v-model="snapshotDescription" placeholder="Describe what this snapshot is all about!" />
        </div>
        <Button :disabled="!snapshotDescription.length" class="w-[200px]" :loading="loading" @click="saveSnapshot">Save Snapshot</Button>
    </Dialog>
</template>

<script setup lang="ts">
import { ref, watch, inject } from "vue";

const visible = ref(false);
const loading = ref(false);
const snapshotDescription = ref('');

const props = defineProps({
    open: {
        type: Boolean,
        default: false
    }
})

const emit = defineEmits(['updateVisible'])
const toast = inject('toast') as {add: Function};
const updateVisible = (val: boolean) => {
    emit('updateVisible', val)
}
watch(() => props.open, (newVal) => {
    visible.value = newVal;
})

const saveSnapshot = async () => {
    loading.value = true;
    try {
        const configStore = useConfigStore()
        await configStore.saveSnapshot(snapshotDescription.value)
        toast.add({
            severity: 'success',
            summary: 'Snapshot saved successfully!',
            life: 1500
        })
        snapshotDescription.value = '';
        visible.value = false;
        updateVisible(false);
    }catch(e) {
        console.log(e)
    }
    finally {
        loading.value = false;
    }
}
</script>

<style scoped></style>