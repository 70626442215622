import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import {
  fetchCoreData,
  fetchBuilderData,
  fetchLiveStreamData,
  fetchDataLastReceived
} from '../services/reportService'
import { processChartData } from '../services/chartDataService'
import type { Question, Offer } from '@/types/config'
import { changeDateToStartAtMidnight } from '@/services/date'

// const START_DATE_CONSTANT = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)

export const useReportStore = defineStore('report', () => {
  const configStore = useConfigStore()
  const { currentTeamPid } = storeToRefs(configStore)
  const offerStore = useOfferStore()
  const { offers } = storeToRefs(offerStore)

  const dateRange = ref({
    start: changeDateToStartAtMidnight(),
    end: new Date()
  })

  const builderParams = ref({
    segmentGroups: {},
    questionIds: [],
    splitOnOffers: false,
    optInMode: true,
    fallbackAnonSegmentId: null,
    selectedOffers: []
  })

  watch(currentTeamPid, () => {
    // Reset date range to last 7 days
    dateRange.value = {
      start: changeDateToStartAtMidnight(),
      end: new Date()
    }

    // Reset builder params to default values
    builderParams.value = {
      segmentGroups: {},
      questionIds: [],
      splitOnOffers: false,
      optInMode: true,
      fallbackAnonSegmentId: null,
      selectedOffers: []
    }
  })

  const canFetchReports = computed(
    () => !!(currentTeamPid.value && dateRange.value)
  )

  const coreQuery = useQuery({
    queryKey: ['coreData', currentTeamPid, dateRange],
    queryFn: () => fetchCoreData(currentTeamPid.value, unref(dateRange)),
    enabled: canFetchReports
  })

  const selectedOffers = computed(() => builderParams.value.selectedOffers)

  const builderQuery = useQuery({
    queryKey: ['builderData', currentTeamPid, dateRange, selectedOffers],
    queryFn: async () => {
      await configStore.configQuery.refetch()
      return fetchBuilderData(
        currentTeamPid.value,
        {
          offers: [...offers.value].filter(Boolean)
            .map((o) => o.id)
            .filter((o) =>
              selectedOffers.value.length === 0
                ? true
                : selectedOffers.value.includes(o)
            ),
          ...unref(builderParams)
        },
        unref(dateRange)
      )
    },
    enabled: canFetchReports
  })

  // watch(offers, () => {
  //   builderQuery.refetch()
  // })

  // const liveStreamQuery = useQuery({
  //   queryKey: ['liveStreamData'],
  //   queryFn: fetchLiveStreamData,
  //   refetchInterval: 60000 // Refetch every minute
  // })

  const {
    data: dataLastReceived,
    isLoading: isLoadingDataLastReceived,
    dataUpdatedAt: dataLastReceivedUpdatedAt
  } = useQuery({
    queryKey: ['dataLastReceived', currentTeamPid],
    queryFn: () => fetchDataLastReceived(currentTeamPid.value),
    enabled: canFetchReports,
    refetchInterval: 1000 * 60
  })

  const coreData = computed(() => coreQuery.data.value)

  const coreCharts = computed(() =>
    processChartData(coreQuery.data, builderQuery.data, builderParams)
  )

  const orderedCoreCharts = computed(() =>
    coreCharts.value.filter((chart) => chart.enabled)
  )

  const metricsForQuestion = computed(() => {
    return (question: Question) => {
      const questionData =
        coreData.value?.questionsData?.questionBreakdowns?.find(
          (q) => q.questionId === question.id
        )
      if (questionData) {
        const conversionRate =
          100 * (questionData.visitorsConverted / questionData.visitorsExposed)
        return [
          {
            label: 'Asks',
            value: questionData.visitorsExposed
          },
          {
            label: 'Completions',
            value: questionData.visitorsConverted
          },
          { label: 'Answer Rate', value: conversionRate.toFixed(2) + '%' }
        ]
      }
      return [
        {
          label: 'Asks',
          value: 0
        },
        {
          label: 'Completions',
          value: 0
        },
        {
          label: 'Answer Rate',
          value: '0%'
        }
      ]
    }
  })

  const metricsForOffer = computed(() => {
    return (offer: Offer) => {
      const offerData = coreData.value?.offersData?.offerBreakdowns?.find(
        (o) => o.offerId === offer.id
      )
      if (offerData) {
        const conversionRate =
          100 * (offerData.visitorsConverted / offerData.visitorsExposed)
        return [
          {
            label: 'Displays',
            value: offerData.visitorsExposed
          },
          {
            label: isForm(offer) ? 'Conversions' : 'Clicks',
            value: offerData.visitorsConverted
          },
          {
            label: isForm(offer) ? 'Conversion Rate' : 'Conversion Rate',
            value: conversionRate.toFixed(2) + '%'
          }
        ]
      }
      return [
        {
          label: 'Displays',
          value: 0
        },
        {
          label: isForm(offer) ? 'Conversions' : 'Clicks',
          value: 0
        },
        {
          label: isForm(offer) ? 'Conversion Rate' : 'Conversion Rate',
          value: '0%'
        }
      ]
    }
  })

  const isLoading = computed(
    () => coreQuery.isLoading || builderQuery.isLoading
  )

  return {
    coreQuery,
    builderQuery,
    dateRange,
    builderParams,
    coreCharts,
    coreData,
    orderedCoreCharts,
    metricsForQuestion,
    metricsForOffer,
    // liveStreamData: liveStreamQuery.data,
    dataLastReceived,
    isLoadingDataLastReceived,
    dataLastReceivedUpdatedAt,
    isLoading
  }
})
