import { defineStore } from 'pinia'
import { klona } from 'klona'
import { makeId, idPrefixes, uuid } from '@/services/uuid'
import { Widget, Flow, widgetSchema } from '@/types/config'
import { sluggify } from '@/services/string'

export const useWidgetStore = defineStore('widget', () => {
  const configStore = useConfigStore()
  const { config } = storeToRefs(configStore)
  const flowStore = useFlowStore()
  const { flows } = storeToRefs(flowStore)

  const widgets = computed(() => config.value?.rightCta?.widgets || [])
  const getById = (id: string) =>
    widgets.value.find((widget) => widget.id === id)

  const getWidgetsForOfferFunnel = (offerFunnelId: string) =>
    widgets.value.filter((widget) => widget.contentId === offerFunnelId)

  const getWidgetsForOffer = (offerId: string) => {
    return flows.value
      .filter((flow: Flow) => {
        let found = false
        funnelDefinitionEach(flow.funnelDefinition, (node: any) => {
          if (node.type === 'offer' && node.offerId === offerId) {
            found = true
          }
        })
        return found
      })
      .flatMap((flow: Flow) => flow.id)
      .reduce((uniqueWidgets: Widget[], flowId: string) => {
        const matchingWidgets = widgets.value.filter(
          (widget) => widget.contentId === flowId
        )
        return [
          ...uniqueWidgets,
          ...matchingWidgets.filter(
            (widget) =>
              !uniqueWidgets.some(
                (existingWidget) => existingWidget.id === widget.id
              )
          )
        ]
      }, [])
  }

  const create = ({
    type,
    name,
    theme,
    contentId
  }: {
    type: string
    name: string
    theme: string
    contentId: string
  }) => {
    const newWidget = widgetSchema.parse({
      id: makeId(idPrefixes.WIDGET),
      name,
      type,
      contentId,
      isEnabled: true,
      visibility: {
        isBlacklistEnabled: false,
        isWhitelistEnabled: false,
        whitelist: [],
        blacklist: [],
        trigger: 'immediate',
        areas: type === 'inline' ? sluggify(name) : undefined
      },
      whenToAskNext: 'immediately',
      options: createWidgetDefaults(type, theme)
    }) as Widget

    widgets.value.push(newWidget)

    recordAnalyticsEvent(AnalyticsEventTypes.WIDGET_CREATED, {
      id: newWidget.id,
      type: newWidget.type,
      name: newWidget.name
    })

    return newWidget
  }

  const destroy = (widget: Widget) => {
    const foundIndex = widgets.value.findIndex((w) => w.id == widget.id)
    if (foundIndex === -1) return // this should really never happen

    widgets.value.splice(foundIndex, 1)
  }

  const duplicate = (widget: Widget) => {
    const newWidget = {
      ...klona(widget),
      id: makeId(idPrefixes.WIDGET),
      name: `${widget.name} (Copy)`
    }

    widgets.value.push(newWidget)

    return newWidget
  }

  return {
    widgets,
    getById,
    getWidgetsForOfferFunnel,
    getWidgetsForOffer,
    create,
    destroy,
    duplicate
  }
})
