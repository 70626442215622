import { defineStore } from 'pinia'
import { computed } from 'vue'
import { useConfigStore } from '@/stores/configStore'
import { makeId, idPrefixes } from '@/services/uuid'
import { Phrase, phraseSchema } from '@/types/config'

export const usePhraseStore = defineStore('phrase', () => {
  const configStore = useConfigStore()
  const { config } = storeToRefs(configStore)

  const templateStore = useTemplateStore()
  const { phrases: tmpPhrases } = storeToRefs(templateStore)

  const phrases = computed(() => {
    const realPhrases = config.value?.phrases || []
    const templatePhrases = tmpPhrases.value || []
    return [...realPhrases, ...templatePhrases]
  })

  const getById = (id: string) =>
    phrases.value.find((phrase) => phrase.id === id)

  const upsert = (phrase: Partial<Phrase>) => {
    const existingPhrase = getById(phrase.id || '')
    if (existingPhrase) {
      existingPhrase.fallbackText = phrase.fallbackText
      existingPhrase.map = phrase.map
      existingPhrase.segmentGroupId = phrase.segmentGroupId
      return existingPhrase
    } else {
      const newPhrase = {
        id: makeId(idPrefixes.PHRASE),
        ...phrase
      } as Phrase

      config.value?.phrases?.push(newPhrase)
      return newPhrase
    }
  }

  const destroy = (phrase: Phrase) => {
    const foundIndex = config.value?.phrases?.findIndex(
      (p) => p.id === phrase.id
    )
    if (foundIndex === -1) return // this should really never happen

    config.value?.phrases?.splice(foundIndex, 1)
  }

  return {
    phrases,
    getById,
    upsert,
    destroy
  }
})
