import { Liquid } from 'liquidjs'
import { Dimension } from '@/types/config'

const engine = new Liquid()

export const cleanupLiquid = (email: string) => {
  email = email || ''
  email = email.replace(/{-%/g, '{%-')
  email = email.replace(/%-}/g, '-%}')
  email = email.replace(/-%>/g, '-%}')
  return email
}

export const personalizeEmailWithLiquid = (email: string, data: any) => {
  email = cleanupLiquid(email)

  email = email.replace(
    /({%-?\s*if\s+([a-zA-Z][a-zA-Z0-9_]*(?:\.[a-zA-Z][a-zA-Z0-9_]*)*)\s*==\s*"([^"]*)"[\s\S]*?{%-?\s*endif\s*-?%})/g,
    (match, full, token, value) =>
      `<span class="bg-yellow-200/50 shadow-sm px-0.5 py-0.5" data-token-id="${token.trim()}" data-token-value="${value.trim()}">${full}</span>`
  )

  try {
    return engine.parseAndRenderSync(email, data)
  } catch (error) {
    return 'ERROR'
  }
}

export const segmentCombinationsPossible = (segmentGroups: Dimension[]) => {
  const dimensions = segmentGroups.map((sg) => ({
    dimensionId: sg.id,
    segments: sg.segments.map((s) => s.id)
  }))

  function combine(
    index: number,
    current: Array<{ dimensionId: string; segmentId: string | null }>
  ) {
    // Base case: if we've processed all dimensions, return the current combination
    if (index === dimensions.length) {
      // Create a unique ID by joining dimension and segment IDs
      const id = current
        .map(
          ({ dimensionId, segmentId }) =>
            `${dimensionId}:${segmentId || 'null'}`
        )
        .join('_')
      return [{ id, segments: current }]
    }

    const result = []
    const currentDimension = dimensions[index]

    // For each possible segment in the current dimension (including null)
    for (const segmentId of currentDimension.segments) {
      result.push(
        ...combine(index + 1, [
          ...current,
          {
            dimensionId: currentDimension.dimensionId,
            segmentId
          }
        ])
      )
    }

    return result
  }

  return combine(0, [])
}
