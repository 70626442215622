import { defineStore } from 'pinia'
import { useMutation } from '@tanstack/vue-query'
import api, { noAuthApi } from '@/services/axiosApi'
import { md5 } from 'js-md5'
import { invoke, until } from '@vueuse/core'
import { User } from '@/types/user'
import { useNotificationStore } from './notificationStore'

export const useAuthStore = defineStore('auth', () => {
  const router = useRouter()
  const route = useRoute()
  const user = ref<User | null>(null)
  const isAuthenticated = ref(false)

  const listenForErrorBroadcast = (id: any) => {
    Echo.private(`error-exception.${id}`).listen(
      'GeneralExceptionBroadcaster',
      (data) => {
        if (data.message) {
          const notificationStore = useNotificationStore()
          notificationStore.addError(data.message)
        }
      }
    )
  }
  const getUser = async (force = false) => {
    if (user.value && !force) {
      listenForErrorBroadcast(user.value.id);
      return user.value
    }
    const res = await api.get('/api/user')
    user.value = res.data
    listenForErrorBroadcast(user.value?.id)
    if (user.value?.trial_ends_at) {
      user.value.trial_ends_at = new Date(user.value.trial_ends_at)
    }
    if (window.Beacon) {
      window.Beacon('identify', {
        email: user.value?.email,
        ...(user.value?.name && { name: user.value.name }),
        signature: user.value?.helpscout_beacon
      })
    }
    api.get('/api/user_cookie')
    isAuthenticated.value = true
  }

  const postLogin = () => {
    if (route.query.redirect) {
      router.push(route.query.redirect as string)
    } else {
      getUser().then(async () => {
        const teamStore = useTeamStore()
        const { teams } = storeToRefs(teamStore)

        const handleRedirect = () => {
          router.push({
            name: ROUTES.DASHBOARD,
            params: { teamPid: teams.value[0].pid }
          })
        }
        if (teams.value?.length > 0) {
          handleRedirect()
        }
        invoke(async () => {
          await until(teams).toBeTruthy()
          if (teams.value?.length > 0) {
            handleRedirect()
          } else {
            router.push({ name: ROUTES.SETUPBLANKUSER })
          }
        })
      })
    }
  }

  const getResetPasswordToken = async (email: string) => {
    const res = await api.get(`/get-reset-token/${email}`)
    return res.data
  }

  const loginMutation = useMutation({
    mutationFn: async (credentials: { email: string; password: string }) => {
      await api.get('/sanctum/csrf-cookie')
      return api.post('/login', credentials)
    },
    onSuccess: postLogin
  })
  const forgotPasswordMutation = useMutation({
    mutationFn: async (credentials: { email: string }) => {
      await api.get('/sanctum/csrf-cookie')
      return api.post('/forgot-password', credentials)
    }
  })
  const resetPasswordMutation = useMutation({
    mutationFn: async (credentials: {
      email: string
      password: string
      confirmPassword: string
      token: string
    }) => {
      await api.get('/sanctum/csrf-cookie')
      return api.post('/reset-password', {
        email: credentials.email,
        password: credentials.password,
        token: credentials.token
      })
    }
  })

  const login = () => {
    const { isError, error, isPending, mutate } = loginMutation
    return { isPending, error, isError, mutate }
  }

  const forgotPassword = () => {
    const { isError, error, isPending, mutate, mutateAsync } =
      forgotPasswordMutation
    return { isPending, error, isError, mutate, mutateAsync }
  }

  const resetPassword = () => {
    const { isError, error, isPending, mutate, mutateAsync } =
      resetPasswordMutation
    return { isPending, error, isError, mutate, mutateAsync }
  }

  const logoutMutation = useMutation({
    mutationFn: () => api.post('/logout'),
    onSuccess: () => {
      user.value = null
      isAuthenticated.value = false
    }
  })

  const logout = () => logoutMutation.mutate()

  const updateProfileMutation = useMutation({
    mutationFn: (data: any) => api.put('/user/profile-information', data),
    onSuccess: (_, data) => {
      user.value = { ...user.value, ...data }
      const notificationStore = useNotificationStore()
      notificationStore.addNotification('Profile updated successfully')
    }
  })

  const updateProfile = (data: any) => updateProfileMutation.mutate(data)

  const updatePasswordMutation = useMutation({
    mutationFn: (data: any) => api.put('/user/password', data),
    onSuccess: (_, data) => {
      const notificationStore = useNotificationStore()
      notificationStore.addNotification('Password updated successfully')
    }
  })

  const updatePassword = (data: any) => updatePasswordMutation.mutate(data)

  const activateAccountMutation = useMutation({
    mutationFn: async (variables: {
      hash: string
      email: string
      password: string
    }) => {
      await api.get('/sanctum/csrf-cookie')
      return api.post(`/api/invitations/${variables.hash}`, {
        email: variables.email,
        password: variables.password
      })
    },
    onSuccess: ({ data }) => {
      if (data.teamPid) {
        router.push({
          name: ROUTES.DASHBOARD,
          params: { teamPid: data.teamPid }
        })
      }
    }
  })

  const activateAccount = (variables: {
    hash: string
    email: string
    password: string
  }) => activateAccountMutation.mutate(variables)

  const profilePhotoUrl = computed(() => {
    if (!user.value || !user.value.email) return ''
    const hash = md5(user.value.email.toLowerCase().trim())
    return `https://www.gravatar.com/avatar/${hash}?s=200&d=mp`
  })

  const isAdmin = computed(() => {
    return user.value?.is_admin
  })

  return {
    user,
    isAuthenticated,
    getUser,
    postLogin,
    login,
    logout,
    resetPassword,
    forgotPassword,
    updateProfile,
    updatePassword,
    getResetPasswordToken,
    activateAccount,
    profilePhotoUrl,
    isAdmin
  }
})
