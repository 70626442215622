import { z } from 'zod'

// Helper schemas
const actionId = z.string().regex(/^act_/)
const campaignId = z.string().regex(/^cpn_/)
const dimensionId = z.string().regex(/^dim_/)
const eventId = z.string().regex(/^gev_/)
const goalId = z.string().regex(/^gol_/)
const widgetId = z.string().regex(/^wdg_/)
const offerFunnelId = z.string().regex(/^ofn_/)
const offerId = z.string().regex(/^ofr_/)
const segmentId = z.string().regex(/^(seg_\w+|\$everyone)$/)

// Page schema
const page = z
  .array(
    z.object({
      domain: z.string().default('*'),
      path: z.string().default('*')
    })
  )
  .default([])

// LogicDefinition schema

const logicDefinitionItem = z.object({
  $type: z.string(),
  $source: z.string().optional(),
  operator: z.enum(['equals']),
  value: z.string(),
  customFieldId: z.string().optional()
})

const logicDefinition = z.object({}).passthrough()

const nodeSgSchema = z.object({
  _uuid: z.string().optional(),
  type: z.literal('sg'),
  segmentGroupId: dimensionId.nullable().optional(),
  ask: z.boolean(),
  split: z.union([
    z.literal(false),
    z.array(
      z.object({
        segmentId: segmentId.nullable(),
        child: []
      })
    )
  ])
})

export type NodeSg = z.infer<typeof nodeSgSchema>

const nodeRedirectSchema = z.object({
  _uuid: z.string().optional(),
  type: z.literal('redirect'),
  url: z.string(),
  open: z.boolean()
})

export type NodeRedirect = z.infer<typeof nodeRedirectSchema>

// FunnelDefinitionNode schema
const funnelDefinitionNode = z.lazy(() =>
  z.discriminatedUnion('type', [
    nodeSgSchema,
    z.object({
      _uuid: z.string().optional(),
      type: z.literal('random'),
      split: z.array(
        z.object({
          child: funnelDefinitionNodeRun
        })
      )
    }),
    z.object({
      _uuid: z.string().optional(),
      type: z.literal('yesno'),
      condition: logicDefinition,
      note: z.string().optional(),
      yes: funnelDefinitionNodeRun,
      no: funnelDefinitionNodeRun
    }),
    z.object({
      _uuid: z.string().optional(),
      type: z.literal('offer'),
      desiredType: z.enum(['offer', 'form']).optional(),
      offerId: offerId.nullable().optional()
    }),
    nodeRedirectSchema,
    z.object({
      _uuid: z.string().optional(),
      type: z.literal('message'),
      content: z.string()
    }),
    z.object({
      _uuid: z.string().optional(),
      type: z.literal('code'),
      name: z.string(),
      code: z.string(),
      selector: z.string().optional()
    }),
    z.object({
      _uuid: z.string().optional(),
      type: z.literal('exit')
    })
  ])
)

const funnelDefinitionNodeRun = z.array(funnelDefinitionNode)

const funnelDefinitionRootNode = z.object({
  _uuid: z.string().optional(),
  type: z.literal('root'),
  child: funnelDefinitionNodeRun
})

const formSettingsSchema = z.object({
  showLabels: z.boolean()
})

const formFieldSchema = z.object({
  label: z.string().optional(),
  placeholder: z.string().optional(),
  displayType: z.string(),
  width: z.number(),
  options: z.object({}).optional()
})

// TriggeredAction schema
const triggeredAction = z.discriminatedUnion('type', [
  z.object({
    type: z.literal('link'),
    settings: z.object({
      url: z.string().nullable()
    })
  }),
  z.object({
    type: z.literal('tag'),
    settings: z.object({
      partnerName: z.string(),
      tagIds: z.array(z.string()),
      thanksForConvertingText: z.string(),
      redirectFunnel: funnelDefinitionRootNode,
      formSettings: formSettingsSchema.optional(),
      fields: z.array(formFieldSchema).optional()
    })
  }),
  z.object({
    type: z.literal('form'),
    settings: z.object({
      partnerName: z.string().nullable(),
      formIds: z.array(z.string()),
      thanksForConvertingText: z.string().optional(),
      redirectFunnel: funnelDefinitionRootNode,
      formSettings: formSettingsSchema.optional(),
      fields: z.array(formFieldSchema).optional()
    })
  }),
  z.object({
    type: z.literal('list'),
    settings: z.object({
      partnerName: z.string(),
      listIds: z.array(z.string()),
      thanksForConvertingText: z.string(),
      redirectFunnel: funnelDefinitionRootNode,
      formSettings: formSettingsSchema.optional(),
      fields: z.array(formFieldSchema).optional()
    })
  }),
  z.object({
    type: z.literal('event'),
    settings: z.object({
      partnerName: z.string(),
      eventIds: z.union([z.string(), z.array(z.string())]),
      thanksForConvertingText: z.string(),
      redirectFunnel: funnelDefinitionRootNode,
      formSettings: formSettingsSchema.optional(),
      fields: z.array(formFieldSchema).optional()
    })
  })
])

const goalEventSchema = z.object({
  id: eventId,
  type: z.string(),
  selector: z.string().optional(),
  page: page
})

// Main schemas
const goalSchema = z.object({
  id: goalId,
  name: z.string(),
  isVisibleToUser: z.boolean().default(true),
  events: z.array(goalEventSchema).default([])
})

const segmentSchema = z.object({
  id: segmentId,
  name: z.string()
})

const dimensionSchema = z.object({
  id: dimensionId,
  name: z.string(),
  semanticType: z.string().nullable(),
  isMultiWinning: z.boolean().default(false),
  isArchived: z.boolean().default(false),
  favorited: z.boolean().default(false),
  syncTo: z.string().nullable(),
  syncToCustomField: z.string().nullable(),
  segments: z.array(segmentSchema).default([]),
  signals: z
    .array(
      z.object({
        indicates: segmentId,
        weight: z.string(),
        definition: logicDefinition
      })
    )
    .default([])
})

const campaignSchema = z.object({
  id: campaignId,
  name: z.string(),
  is_active: z.boolean().default(true),
  base_url: z.string(),
  recipe_code: z.string().nullable(),
  dimension_id: dimensionId.nullable(),
  goal_ids: z.array(goalId).default([]),
  condition: z.union([z.boolean(), z.object({})]).default(true),
  testing: z.object({
    is_enabled: z.boolean().default(true),
    withhold: z.number().default(10)
  }),
  actions: z
    .array(
      z.object({
        id: actionId,
        $type: z.string(),
        page: page,
        selector: z.string().optional(),
        variations: z
          .array(
            z.object({
              segment_id: segmentId,
              modifications: z.object({}).optional()
            })
          )
          .default([])
      })
    )
    .default([])
})

// Define the schema for the options
const optionSchema = z.object({
  id: z.string().startsWith('rqo_'),
  segmentId: z.string().startsWith('seg_'),
  label: z.string(),
  conditions: logicDefinition
})

// Define the schema for otherOptions
const otherOptionsSchema = z
  .object({
    enabled: z.boolean().default(false),
    otherLabel: z.string().optional().default('Other'),
    inputType: z.string().optional().default('textarea'),
    inputPrompt: z.string().optional(),
    inputPlaceholder: z.string().optional(),
    nextPrompt: z.string().optional().default('Next')
  })
  .nullable()

// Define the main question schema
const questionSchema = z.object({
  id: z.string().startsWith('raq_'),
  text: z.string(),
  dimensionId: z.string().startsWith('dim_'),
  displayType: z.string().default('buttons'),
  isRandomizingOptionsOrder: z.boolean().default(false),
  otherOptions: otherOptionsSchema,
  options: z.array(optionSchema).default([])
})

const rightAskSchema = z.object({
  questions: z.array(questionSchema).default([])
})

const widgetOptionsSchema = z.object({
  theme: z.string(),
  palette: z.object({
    borderColor: z.string(),
    contrastColor: z.string(),
    backgroundColor: z.string(),
    textOnContrastColor: z.string(),
    textOnBackgroundColor: z.string()
  }),
  fontSize: z.object({
    lineHeight: z.number(),
    headlineFontSize: z.string(),
    formFieldFontSize: z.string(),
    descriptionFontSize: z.string(),
    answerButtonFontSize: z.string(),
    submitButtonFontSize: z.string()
  }),
  customCss: z.string(),
  placement: z.string().nullable(),
  structure: z.object({
    formFieldSpacing: z.string(),
    buttonBorderRadius: z.string(),
    answerButtonSpacing: z.string(),
    containerBorderWidth: z.string(),
    containerBorderRadius: z.string()
  }),
  displayTargets: z.object({
    phones: z.boolean(),
    desktop: z.boolean(),
    tablets: z.boolean()
  }),
  style: z.object({
    shadowSize: z.string()
  }),
  backButtonLabel: z.string(),
  backButtonEnabled: z.boolean(),
  buttonAnimationTrigger: z.string()
})

const widgetSchema = z.object({
  id: widgetId,
  name: z.string().optional(),
  type: z.string().optional(),
  isEnabled: z.boolean().optional(),
  options: widgetOptionsSchema,
  whenToAskNext: z
    .enum(['startover', 'immediately', 'pageview', 'session', 'none'])
    .optional(),
  visibility: z
    .object({
      isWhitelistEnabled: z.boolean(),
      whitelist: page,
      isBlacklistEnabled: z.boolean(),
      blacklist: page,
      trigger: z.string().optional(),
      areas: z.string().optional()
    })
    .optional(),
  contentId: z.union([offerFunnelId, offerId]).nullable().optional()
})

const flowSchema = z.object({
  id: offerFunnelId,
  name: z.string(),
  funnelDefinition: funnelDefinitionRootNode
})

const offerSchema = z.object({
  id: offerId,
  name: z.string(),
  isEnabled: z.boolean(),
  parts: z.object({
    title: z.string(),
    description: z.string().nullable(),
    buttonText: z.string().nullable(),
    imageUrl: z.string().optional()
  }),
  triggeredAction: triggeredAction
})

const rightCtaSchema = z.object({
  settings: z
    .object({
      isEnabled: z.boolean().optional(),
      foregroundColor: z.string().optional(),
      backgroundColor: z.string().optional(),
      buttonForegroundColor: z.string().optional(),
      buttonBackgroundColor: z.string().optional(),
      isBrandingHidden: z.boolean().optional(),
      thanksForAnsweringText: z.string().optional(),
      submittingText: z.string().optional(),
      whyAreWeAskingThisText: z.string().optional(),
      emailFieldLabel: z.string().optional(),
      questionAnswerLabel: z.string().optional(),
      ga: z.boolean().optional(),
      disablePrefillFormFields: z.boolean().optional()
    })
    .optional(),
  widgets: z.array(widgetSchema).optional(),
  offerFunnels: z.array(flowSchema).optional(),
  offers: z.array(offerSchema).optional()
})

const settingsSchema = z.object({
  clientStorage: z.enum(['cookies', 'localStorage']).optional(),
  crossDomainTracking: z.boolean().optional(),
  jsHooks: z.object({}).optional(),
  integrations: z.array(z.object({ name: z.string(), enabled: z.boolean() }))
})

const phraseSchema = z.object({
  id: z.string(),
  fallbackText: z.string(),
  segmentGroupId: z.string().nullable(),
  map: z.object({})
})

const attributeSchema = z.object({
  label: z.string(),
  value: z.string(),
  internal: z.boolean(),
  overwrite: z.boolean(),
  destination: z.string()
})

// Config schema
const configSchema = z.object({
  id: z.number(),
  version: z.literal('2024-01-01'),
  domainNames: z.array(z.string()).default([]),
  onboarding: z.object({}).default({}),
  settings: settingsSchema.default({}),
  goals: z.array(goalSchema).default([]),
  dimensions: z.array(dimensionSchema).default([]),
  campaigns: z.array(campaignSchema).default([]),
  rightAsk: rightAskSchema.default({}),
  rightCta: rightCtaSchema.optional(),
  phrases: z.array(phraseSchema).optional(),
  attributes: z.array(attributeSchema).optional()
})

// Export types
export type Config = z.infer<typeof configSchema>
export type Goal = z.infer<typeof goalSchema>
export type GoalEvent = z.infer<typeof goalEventSchema>
export type Dimension = z.infer<typeof dimensionSchema>
export type Segment = z.infer<typeof segmentSchema>
export type LogicDefinition = z.infer<typeof logicDefinition>
export type LogicDefinitionItem = z.infer<typeof logicDefinitionItem>
export type Campaign = z.infer<typeof campaignSchema>
export type RightAsk = z.infer<typeof rightAskSchema>
export type Question = z.infer<typeof questionSchema>
export type QuestionOption = z.infer<typeof optionSchema>
export type RightCta = z.infer<typeof rightCtaSchema>
export type Widget = z.infer<typeof widgetSchema>
export type Offer = z.infer<typeof offerSchema>
export type Flow = z.infer<typeof flowSchema>
export type FunnelDefinitionNode = z.infer<typeof funnelDefinitionNode>
export type FunnelDefinitionRootNode = z.infer<typeof funnelDefinitionRootNode>
export type TriggeredAction = z.infer<typeof triggeredAction>
export type Settings = z.infer<typeof settingsSchema>
export type Attribute = z.infer<typeof attributeSchema>
export type Phrase = z.infer<typeof phraseSchema>
// Export schemas
export {
  configSchema,
  goalSchema,
  goalEventSchema,
  dimensionSchema,
  campaignSchema,
  rightAskSchema,
  rightCtaSchema,
  flowSchema,
  funnelDefinitionNode,
  funnelDefinitionRootNode,
  triggeredAction,
  questionSchema,
  offerSchema,
  widgetSchema,
  attributeSchema,
  phraseSchema
}
