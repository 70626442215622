import { ROUTES } from './routeNames'
import auth from './middleware/auth'

import AuthenticatedLayout from '@/components/layouts/AuthenticatedLayout.vue'

import dashboardRoutes from '@/modules/dashboard/dashboardRoutes'
import settingsRoutes from '@/modules/settings/settingsRoutes'
import segmentRoutes from '@/modules/segment/segmentRoutes'
import integrationRoutes from '@/modules/integration/integrationRoutes'
import flowRoutes from '@/modules/flow/flowRoutes'
import widgetRoutes from '@/modules/widget/widgetRoutes'
import offerRoutes from '@/modules/offer/offerRoutes'
import personalizationRoutes from '@/modules/personalization/personalizationRoutes'
import insightsRoutes from '@/modules/insights/insightsRoutes'
import attributionRoutes from '@/modules/attribution/attributionRoutes'
import onboardingRoutes from '@/modules/onboarding/onboardingRoutes'
import templateRoutes from '@/modules/template/templateRoutes'
export default [
  {
    name: ROUTES.HOME,
    path: '/',
    beforeEnter: async (to, from, next) => {
      const authStore = useAuthStore()
      authStore.postLogin()
      next()
    },
    meta: { middleware: [auth] }
  },
  {
    name: ROUTES.LOGIN,
    path: '/login',
    component: () => import('@/modules/user/pages/Login.vue')
  },
  {
    name: ROUTES.FORGOTPASSWORD,
    path: '/forgot-password',
    component: () => import('@/modules/user/pages/ForgotPassword.vue')
  },
  {
    name: ROUTES.RESETPASSWORD,
    path: '/reset-password',
    component: () => import('@/modules/user/pages/ResetPassword.vue')
  },
  {
    name: ROUTES.LOGOUT,
    path: '/logout',
    beforeEnter: async (_, __, next) => {
      const authStore = useAuthStore()
      authStore.logout()
      next({ name: ROUTES.LOGIN })
    }
  },
  {
    name: ROUTES.REGISTER,
    path: '/register',
    component: () => import('@/modules/user/pages/Register.vue')
  },
  {
    name: ROUTES.ACCEPTINVITATION,
    path: '/accept',
    component: () => import('@/modules/user/pages/AcceptInvitation.vue')
  },
  {
    name: ROUTES.SETUPBLANKUSER,
    path: '/setup',
    component: () => import('@/modules/user/pages/SetupBlankUser.vue')
  },
  {
    path: '/:teamPid',
    component: AuthenticatedLayout,
    meta: { middleware: [auth] },
    children: [
      ...onboardingRoutes,
      ...dashboardRoutes,
      ...settingsRoutes,
      ...segmentRoutes,
      ...integrationRoutes,
      ...flowRoutes,
      ...widgetRoutes,
      ...offerRoutes,
      ...personalizationRoutes,
      ...insightsRoutes,
      ...attributionRoutes,
      ...templateRoutes,
      {
        path: '/:teamPid/:pathMatch(.*)*',
        name: ROUTES.NOTFOUNDAUTH,
        component: () => import('@/components/pages/NotFound.vue')
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: ROUTES.NOTFOUND,
    component: () => import('@/components/pages/NotFound.vue')
  }
]
