import api from '@/services/axiosApi'

const calculatePreviousPeriod = (dateRange: { start: Date; end: Date }) => {
  const periodLength = dateRange.end.getTime() - dateRange.start.getTime()
  return {
    start: new Date(dateRange.start.getTime() - periodLength),
    end: new Date(dateRange.start.getTime())
  }
}

export const fetchCoreData = async (teamPid: number, dateRange) => {
  const { data: legacyData } = await api.get(`/api/${teamPid}/metrics/legacy`, {
    params: {
      start: dateRange.start,
      end: dateRange.end,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }
  })

  const { data: coreData } = await api.get(
    `/api/${teamPid}/metrics/dashboardCoreData`,
    {
      params: {
        start: dateRange.start,
        end: dateRange.end,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    }
  )

  const previousPeriod = calculatePreviousPeriod(dateRange)
  const { data: previousPeriodData } = await api.get(
    `/api/${teamPid}/metrics/dashboardCoreData`,
    {
      params: {
        start: previousPeriod.start,
        end: previousPeriod.end,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    }
  )

  return { ...legacyData, ...coreData, previousPeriod: previousPeriodData }
}

export const fetchBuilderData = async (
  teamPid: number,
  builderParams: object,
  dateRange: { start: Date; end: Date }
) => {
  const { data: builderData } = await api.post(
    `/api/${teamPid}/metrics/queryOfferPerformance`,
    {
      ...builderParams
    },
    {
      params: { start: dateRange.start, end: dateRange.end }
    }
  )

  const buckets = [...builderData]

  if (buckets.length === 1 && buckets[0].for[0] === 'overall') {
    const emptyFor = JSON.parse(JSON.stringify(buckets[0]))
    emptyFor.for = []
    buckets.push(emptyFor)
  }

  const overallBucketIndex = buckets.findIndex(
    (b) => b.for.length === 1 && b.for[0] === 'overall'
  )
  const overallBucket = buckets.splice(overallBucketIndex, 1)[0]
  buckets.forEach((b) => {
    b.data.forEach((d) => {
      d.rate = d.denominator ? d.numerator / d.denominator : 0
      d.share = overallBucket.numerator
        ? d.numerator / overallBucket.numerator
        : 0
    })
    b.rate = b.denominator ? b.numerator / b.denominator : 0
    b.share = overallBucket.numerator
      ? b.numerator / overallBucket.numerator
      : 0
  })
  return {
    buckets,
    overallBucket,
    payloadParts: {
      ...builderParams
    }
  }
}

export const fetchLiveStreamData = async () => {
  // Implement the live stream data fetch logic here
}

export const fetchDataLastReceived = async (teamPid: number) => {
  const { data } = await api.get(`/api/${teamPid}/metrics/dataLastReceived`)

  return data
}
