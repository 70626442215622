import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { VueQueryPlugin, QueryClient } from '@tanstack/vue-query'
import ToastService from 'primevue/toastservice'
import PrimeVue from 'primevue/config'
import ConfirmationService from 'primevue/confirmationservice'
import VueApexCharts from 'vue3-apexcharts'
import { GesturePlugin } from '@vueuse/gesture'
import RightMessageTheme from './assets/theme/primevue'
import router from '@/router'

import * as Sentry from '@sentry/vue'

import App from '@/App.vue'
import './assets/css/app.scss'
import Ripple from 'primevue/ripple'
import 'iconify-icon'
import './services/pusher'

const pinia = createPinia()
const app = createApp(App)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      throwOnError: false
    }
  }
})

window.addEventListener('unhandledrejection', (event) => {
  if (event.reason?.toString().includes('TypeError: Failed to fetch')) {
    alert(
      "There's been a new change to RightMessage. We'll reload the app for you."
    )
    window.location.reload()
  }
})

Sentry.init({
  app,
  dsn: 'https://d500631c71385d011c3a5774b7bd23d0@o4508234535206912.ingest.us.sentry.io/4508234559324160',
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration()
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['new.rightmessage.com'],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: process.env.NODE_ENV === 'production'
})

app.use(pinia)
app.use(VueQueryPlugin, { queryClient })
app.use(router)
app.use(VueApexCharts)
app.use(PrimeVue, {
  unstyled: true,
  pt: RightMessageTheme
})
app.use(ConfirmationService)
app.use(GesturePlugin)
app.use(ToastService)
app.directive('ripple', Ripple)
app.mount('#app')
