import { ROUTES } from '@/router/routeNames'

export default [
  {
    path: 'questions',
    children: [
      {
        name: ROUTES.QUESTIONS,
        path: '',
        component: () => import('./pages/QuestionsIndex.vue'),
        meta: {
          title: 'Audience Questions',
          description:
            'These are questions you can ask of your visitors within a Flow.'
        }
      },
      {
        name: ROUTES.QUESTION,
        path: ':segmentGroupId',
        component: () => import('./pages/SegmentGroupShow.vue'),
        meta: { title: 'Question' },
        props: true
      }
    ]
  },
  {
    path: 'segment-groups',
    children: [
      {
        name: ROUTES.SEGMENTGROUPS,
        path: '',
        component: () => import('./pages/SegmentGroupsIndex.vue'),
        meta: {
          title: 'Segment Groups',
          description:
            'These are categories of segments where visitors are segmented entirely automatically. For example: People who arrive on your site via a specific affiliate URL, returning visitors tagged "Customer", those who landed originally on your pricing page, and so on.',
          helpDoc: 'segmentGroups.default',
          helpDocMinimal: true,
          helpDocSuggestions: ['segmentGroups.default']
        }
      },
      {
        name: ROUTES.SEGMENTGROUPTEMPLATES,
        path: 'templates',
        component: () => {},
        meta: {
          title: 'Segment Group Templates',
          description:
            'These are templates for segment groups. You can use these templates to create new segment groups.'
        }
      },
      {
        name: ROUTES.SEGMENTGROUP,
        path: ':segmentGroupId',
        component: () => import('./pages/SegmentGroupShow.vue'),
        meta: {},
        props: true
      }
    ]
  }
]
