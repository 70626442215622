import { defineStore } from 'pinia'
import { computed } from 'vue'
import { useConfigStore } from '@/stores/configStore'
import { storeToRefs } from 'pinia'
import { klona } from 'klona'
import { makeId, idPrefixes, uuid } from '@/services/uuid'
import { Flow, flowSchema } from '@/types/config'

export const useFlowStore = defineStore('flow', () => {
  const configStore = useConfigStore()
  const { config } = storeToRefs(configStore)

  const flows = computed(() => config.value?.rightCta?.offerFunnels || [])
  const getById = (id: string) => flows.value.find((funnel) => funnel.id === id)

  const create = (flow: Partial<Flow>) => {
    // const newFlow = flowSchema.parse({
    //   id: makeId(idPrefixes.FLOW),
    //   name: `New Flow ${flows.value.length + 1}`,
    //   funnelDefinition: { _uuid: uuid(), type: 'root', child: [] },
    //   ...flow
    // }) as Flow

    const newFlow = {
      id: makeId(idPrefixes.FLOW),
      name: `New Flow ${flows.value.length + 1}`,
      funnelDefinition: { _uuid: uuid(), type: 'root', child: [] },
      ...flow
    } as Flow

    flows.value.push(newFlow)

    recordAnalyticsEvent(AnalyticsEventTypes.FLOW_CREATED, {
      id: newFlow.id,
      name: newFlow.name
    })

    return newFlow
  }

  const destroy = (flow: Flow) => {
    const foundIndex = flows.value.findIndex((f) => f == flow)
    if (foundIndex === -1) return // this should really never happen

    const widgetStore = useWidgetStore()
    const widgets = widgetStore.getWidgetsForOfferFunnel(flow.id)
    widgets.forEach((widget) => {
      widget.contentId = null
    })
    flows.value.splice(foundIndex, 1)
  }

  const duplicate = (flow: Flow) => {
    const newFlow = {
      ...klona(flow),
      id: makeId(idPrefixes.FLOW),
      name: `${flow.name} (Copy)`
    }

    funnelDefinitionEach(newFlow.funnelDefinition, (node: any) => {
      node._uuid = uuid()
    })

    flows.value.push(newFlow)

    return newFlow
  }

  return {
    flows,
    getById,
    create,
    destroy,
    duplicate
  }
})
