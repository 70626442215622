import { defineStore } from 'pinia'
import { computed } from 'vue'
import { useConfigStore } from '@/stores/configStore'
import { storeToRefs } from 'pinia'
import { useQuery } from '@tanstack/vue-query'
import { makeId, idPrefixes } from '@/services/uuid'
import { dimensionSchema, Dimension, Question } from '@/types/config'
import api from '@/services/axiosApi'

export const useSegmentGroupStore = defineStore('segmentGroup', () => {
  const configStore = useConfigStore()
  const { config, currentTeamPid } = storeToRefs(configStore)
  const reportStore = useReportStore()
  const { dateRange } = storeToRefs(reportStore)
  const audienceBreakdowns = ref({})
  const templateStore = useTemplateStore()
  const { segmentGroups: tmpSegmentGroups } = storeToRefs(templateStore)

  const segmentGroups = computed(() => {
    const realSegmentGroups = config.value?.dimensions || []
    return [...realSegmentGroups, ...tmpSegmentGroups.value].sort((a, b) =>
      a.name.localeCompare(b.name)
    )
  })
  const getById = (id: string) =>
    segmentGroups.value.find((segmentGroup) => segmentGroup.id === id)

  const segmentGroupForSegmentId = (id: string) =>
    segmentGroups.value.find((sg) => sg.segments.find((s) => s.id === id))

  const segmentForSegmentId = (id: string) =>
    segmentGroupForSegmentId(id)?.segments.find((s) => s.id === id)

  const create = ({
    type,
    segmentGroup,
    question
  }: {
    type: 'segmentgroup' | 'question'
    segmentGroup?: Partial<Dimension>
    question?: Partial<Question>
  }) => {
    const namePrefix = type === 'segmentgroup' ? 'Segment Group' : 'Question'
    const newSegmentGroup = dimensionSchema.parse({
      id: makeId(idPrefixes.DIMENSION),
      name: `New ${namePrefix} ${segmentGroups.value.length + 1}`,
      segments: [],
      signals: [],
      semanticType: 'CUSTOM',
      syncTo: '',
      syncToCustomField: '',
      isArchived: false,
      ...segmentGroup
    }) as Dimension

    if (newSegmentGroup.syncToCustomField) {
      const { integratedPartner } = useIntegrationStore()
      if (integratedPartner) newSegmentGroup.syncTo = integratedPartner.name
    }

    config.value?.dimensions.push(newSegmentGroup)

    if (type === 'question') {
      const newQuestion = questionSchema.parse({
        id: makeId(idPrefixes.RIGHT_ASK_QUESTION),
        dimensionId: newSegmentGroup.id,
        text:
          question?.text ||
          `Ask about ${newSegmentGroup.name.toLocaleLowerCase()}`,
        displayType: 'buttons',
        isRandomizingOptionsOrder: false,
        otherOptions: {
          enabled: false,
          otherLabel: 'Other',
          inputType: 'textarea',
          inputPrompt: 'Please share more details',
          inputPlaceholder: '',
          nextPrompt: 'Next'
        },
        ...question
      }) as Question

      config.value?.rightAsk?.questions.push(newQuestion)
    }

    recordAnalyticsEvent(
      type === 'question'
        ? AnalyticsEventTypes.QUESTION_CREATED
        : AnalyticsEventTypes.SEGMENT_GROUP_CREATED,
      {
        segmentGroupId: newSegmentGroup.id
      }
    )

    return newSegmentGroup
  }

  const destroy = (segmentGroup: Dimension) => {
    const foundIndex = config.value.dimensions.findIndex(
      (sg) => sg.id == segmentGroup.id
    )
    if (foundIndex === -1) return // this should really never happen

    funnelDefinitionForEachFlow((node) => {
      if (node.type === 'sg' && node.segmentGroupId === segmentGroup.id) {
        node.segmentGroupId = null
      }
    })

    logicDefinitionForEachSegmentGroup((condition, nuke) => {
      if (
        condition.$source === 'segments' &&
        condition.$type === segmentGroup.id
      ) {
        nuke()
      }
    })

    const campaignStore = useCampaignStore()
    campaignStore.campaigns.forEach((campaign) => {
      if (campaign.dimension_id === segmentGroup.id) {
        campaignStore.destroy(campaign)
      }
    })

    const questionStore = useQuestionStore()
    questionStore.questions.forEach((question) => {
      if (question.dimensionId === segmentGroup.id) {
        questionStore.destroy(question)
      }
    })

    const phraseStore = usePhraseStore()
    phraseStore.phrases.forEach((phrase) => {
      if (phrase.segmentGroupId === segmentGroup.id) {
        phraseStore.destroy(phrase)
      }
    })

    config.value?.dimensions?.splice(foundIndex, 1)
  }

  const archive = (segmentGroup: Dimension) => {
    const foundIndex = config.value.dimensions.findIndex(
      (sg) => sg.id == segmentGroup.id
    )
    if (foundIndex === -1) return

    config.value.dimensions[foundIndex].isArchived = true
    const notificationStore = useNotificationStore()
    notificationStore.addNotification(`${segmentGroup.name} has been archived.`)
  }

  const unarchive = (segmentGroup: Dimension) => {
    const foundIndex = config.value.dimensions.findIndex(
      (sg) => sg.id == segmentGroup.id
    )
    if (foundIndex === -1) return

    config.value.dimensions[foundIndex].isArchived = false

    const notificationStore = useNotificationStore()
    notificationStore.addNotification(
      `${segmentGroup.name} is no longer archived.`
    )
  }

  const audienceBreakdownQuery = useQuery({
    queryKey: ['audienceBreakdown', currentTeamPid, dateRange],
    queryFn: () => fetchAudienceBreakdown(currentTeamPid.value),
    enabled: computed(() => !!currentTeamPid.value)
  })

  watchEffect(() => {
    if (audienceBreakdownQuery.data.value) {
      audienceBreakdowns.value = audienceBreakdownQuery.data.value.segmentCounts
    }
  })

  const fetchAudienceBreakdown = async (teamPid: string) => {
    return api
      .get(`/api/${teamPid}/metrics/dashboardAudienceBreakdown`, {
        params: {
          start: dateRange.value.start,
          end: dateRange.value.end
        }
      })
      .then((res) => res.data)
  }

  const getAudienceBreakdownForId = (segmentGroupId: string) => {
    const segmentGroup = getById(segmentGroupId)
    if (!segmentGroup) return { total: 0, segments: [] }

    const segmentIds = segmentGroup.segments.map((segment) => segment.id)

    const breakdown = segmentIds.reduce((acc, segmentId) => {
      acc[segmentId] = audienceBreakdowns.value[segmentId] || 0
      return acc
    }, {})

    const total = Object.values(breakdown).reduce(
      (sum, count) => sum + count,
      0
    )

    return {
      total,
      segments: Object.entries(breakdown).map(([id, count]) => ({ id, count }))
    }
  }

  return {
    segmentGroups,
    getById,
    getAudienceBreakdownForId,
    segmentGroupForSegmentId,
    segmentForSegmentId,
    audienceBreakdowns,
    isLoadingAudienceBreakdown: audienceBreakdownQuery.isLoading,
    isErrorAudienceBreakdown: audienceBreakdownQuery.isError,
    create,
    destroy,
    archive,
    unarchive
  }
})
