import { ROUTES } from '@/router/routeNames'

export default [
  {
    name: ROUTES.ATTRIBUTION,
    path: 'attribution',
    component: () => import('./pages/AttributionIndex.vue'),
    meta: {
      title: 'Lead Attribution',
      description:
        'Automatically synchronize data about how a lead ended up on your website to your email platform / CRM.',
      helpDoc: 'attribution.default',
      helpDocMinimal: true
    }
  }
]
