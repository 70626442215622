import { Flow, Offer, Dimension, Widget } from '@/types/config'

export const uuid = () => {
  let d = new Date().getTime()

  if (
    typeof performance !== 'undefined' &&
    typeof performance.now === 'function'
  ) {
    d += performance.now() //use high-precision timer if available
  }

  return ('xxxxxxxx' + 'xxxx' + '4xxx' + 'yxxx' + 'xxxxxxxxxxxx').replace(
    /[xy]/g,
    function (c) {
      const r = (d + Math.random() * 16) % 16 | 0
      d = Math.floor(d / 16)
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
    }
  )
}

export const idPrefixes = {
  ACTION: 'act',
  CAMPAIGN: 'cpn',
  DIMENSION: 'dim',
  GOAL: 'gol',
  GOAL_EVENT: 'gev',
  SEGMENT: 'seg',
  SEGMENT_GROUP: 'dim',
  CAMPAIGN_ACTION: 'act',
  RIGHT_BAR: 'rbr',
  RIGHT_BAR_VARIANT: 'rbv',
  RIGHT_ASK_QUESTION: 'raq',
  RIGHT_ASK_QUESTION_OPTION: 'rqo',
  WIDGET: 'wdg',
  FLOW: 'ofn',
  OFFER: 'ofr',
  PHRASE: 'phr'
}

export const makeId = (
  prefix: string,
  invalidIds: string[] = [],
  length: number = 8
): string => {
  const chars = 'abcdefghijklmnopqrstuvwxyz0123456789'
  let id

  if (invalidIds.length === 0) {
    if (prefix === idPrefixes.FLOW) {
      const flowStore = useFlowStore()
      invalidIds = flowStore.flows.map((f: Flow) => f.id)
    } else if (prefix === idPrefixes.OFFER) {
      const offerStore = useOfferStore()
      invalidIds = offerStore.offers.map((o: Offer) => o.id)
    } else if (prefix === idPrefixes.DIMENSION) {
      const segmentGroupStore = useSegmentGroupStore()
      invalidIds = segmentGroupStore.segmentGroups.map((sg: Dimension) => sg.id)
    } else if (prefix === idPrefixes.WIDGET) {
      const widgetStore = useWidgetStore()
      invalidIds = widgetStore.widgets.map((w: Widget) => w.id)
    }
  }

  do {
    id = prefix + '_'

    for (let i = length; i > 0; --i) {
      id += chars[Math.floor(Math.random() * chars.length)]
    }
  } while (invalidIds.includes(id))

  return id
}

export const inferType = (object: any) => {
  if (!object.id) return null
  const idPrefix = object.id.split('').splice(0, 3).join('')
  return Object.keys(idPrefixes).find((key: string) => {
    return idPrefixes[key] === idPrefix
  })
}
