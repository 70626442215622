import { ROUTES } from '@/router/routeNames'

export default [
  {
    name: ROUTES.INTEGRATIONS,
    path: 'integrations',
    component: () => import('./pages/IntegrationsIndex.vue'),
    meta: {
      title: 'Available Integrations',
      description:
        'Seamlessly integrate your website with your email marketing platform or CRM of choice. Not only can RightMessage associate website visitors with the data you have about them already, but we can also capture new leads and enrich your contacts based on question responses or on-site behavior.'
    }
  },
  {
    name: ROUTES.INTEGRATION,
    path: 'integrations/:integration',
    component: () => import('./pages/IntegrationShow.vue'),
    meta: {
      title: 'Integration'
    }
  }
]
