import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query'
import api from '@/services/axiosApi'
import { Partner } from '@/types/partner'

const partnerLogoOverrides = {
  bento: '/img/onboarding/bento.png',
  userlist: '/img/onboarding/userlist.png'
}

export const useIntegrationStore = defineStore('integration', () => {
  const partners = ref<Partner[]>([])
  const configStore = useConfigStore()
  const { currentTeamPid, currentTeam } = storeToRefs(configStore)

  const queryClient = useQueryClient()

  const hasNewIntegration = computed(() => {
    return currentTeam.value?.uses_v2_integrations
  })

  const transformPartners = (partners: Partner[]) => {
    return partners.map((partner: Partner) => ({
      ...partner,
      logo:
        partnerLogoOverrides[partner.name] ||
        `/img/onboarding/${partner.name}.svg`
    }))
  }

  const partnerEndpoint = computed(() => {
    if (hasNewIntegration.value) {
      return `/api/${currentTeamPid.value}/connections`
    }

    return `/api/${currentTeamPid.value}/integrations/partners`
  })

  /**
   * Fetches and sets the list of partners using Tanstack Query
   */
  const partnersQuery = useQuery({
    queryKey: ['partners', currentTeamPid],
    queryFn: () =>
      api
        .get(partnerEndpoint.value)
        .then((response) => transformPartners(response.data.partners)),
    enabled: computed(() => !!currentTeamPid.value)
  })

  watchEffect(() => {
    if (partnersQuery.data.value) {
      partners.value = klona(unref(partnersQuery.data))
    }
  })

  const saveIntegrationMutation = useMutation({
    mutationFn: (variables: { partner: Partner; data: object }) => {
      if (hasNewIntegration.value) {
        return api.post(
          `/api/${currentTeamPid.value}/connections/${variables.partner.name}`,
          {
            ...variables.data
          }
        )
      }

      return api.post(
        `/api/${currentTeamPid.value}/integrations/${variables.partner.name}`,
        {
          ...variables.data
        }
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['partners', currentTeamPid.value]
      })
    }
  })

  const save = () => {
    const { mutateAsync, error, isPending } = saveIntegrationMutation
    return { mutateAsync, error, isPending }
    // saveIntegrationMutation.mutate({ partner, data })
  }

  const removeIntegrationMutation = useMutation({
    mutationFn: (partner: Partner) => {
      if (hasNewIntegration.value) {
        return api.delete(
          `/api/${currentTeamPid.value}/connections/${partner.name}`
        )
      }

      return api.delete(
        `/api/${currentTeamPid.value}/integrations/${partner.name}`
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['partners', currentTeamPid.value]
      })
    }
  })

  const remove = (partner: Partner) => {
    removeIntegrationMutation.mutate(partner)
  }

  /**
   * Getter for the integrated partner
   */
  const integratedPartner = computed(() =>
    partners.value.find((partner) => partner.hasExistingIntegration === true)
  )

  watch(
    integratedPartner,
    (newVal) => {
      if (!newVal) return
      swapOutEspPlaceholder(newVal)
    },
    { immediate: true }
  )

  const integratedPartnerName = computed(() => integratedPartner.value?.name)

  return {
    partners,
    integratedPartner,
    integratedPartnerName,
    isLoading: partnersQuery.isLoading,
    isError: partnersQuery.isError,
    save,
    remove,
    hasNewIntegration
  }
})
