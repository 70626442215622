import { makeId, idPrefixes } from '@/services/uuid'
import { Dimension, LogicDefinition, Segment } from '@/types/config'
import { Partner } from '@/types/partner'

export const createSegment = (
  segmentGroup: Dimension,
  name?: string,
  id?: string
) => {
  const newSegmentId = makeId(
    idPrefixes.SEGMENT,
    segmentGroup.segments.map((s) => s.id)
  )

  const segment = {
    id: id || newSegmentId,
    name: name || 'New Segment'
  }

  segmentGroup.segments.push(segment)

  const questionStore = useQuestionStore()
  const question = questionStore.getBySegmentGroupId(segmentGroup.id)
  if (question) {
    question.options.push({
      id: makeId(idPrefixes.RIGHT_ASK_QUESTION_OPTION),
      segmentId: id || newSegmentId,
      label: name || 'New Segment',
      conditions: {}
    })
  }

  funnelDefinitionForEachFlow((node) => {
    if (
      node.type === 'sg' &&
      node.split &&
      node.segmentGroupId === segmentGroup.id
    ) {
      node.split.splice(node.split.length - 1, 0, {
        segmentId: segment.id,
        child: []
      })
    }
  })

  return segment
}

export const deleteSegment = (segment: Segment) => {
  const segmentGroupStore = useSegmentGroupStore()
  const segmentGroup = segmentGroupStore.segmentGroupForSegmentId(segment.id)
  if (!segmentGroup) return

  const idx = segmentGroup.segments.findIndex((s) => s.id === segment.id)
  if (idx >= 0) {
    segmentGroup.segments.splice(idx, 1)
  }

  const idx2 = segmentGroup.signals.findIndex((s) => s.indicates === segment.id)
  if (idx2 >= 0) {
    segmentGroup.signals.splice(idx2, 1)
  }

  funnelDefinitionForEachFlow((node) => {
    if (node.type === 'sg' && node.split) {
      node.split = node.split.filter((split) => {
        return split.segmentId !== segment.id
      })
    }
  })

  // const configStore = useConfigStore()
  // const { config } = storeToRefs(configStore)
  // if (!config.value) return
  // // Create a new config object
  // const newConfig = klona(config.value)
  // // Find and update the segment group
  // const segmentGroupIndex = newConfig.dimensions?.findIndex((sg) =>
  //   sg.segments.some((s) => s.id === segment.id)
  // )
  // if (segmentGroupIndex === -1) return
  // const segmentGroup = newConfig.dimensions[segmentGroupIndex]
  // // Find and update the question
  // const questionIndex = newConfig.rightAsk?.questions?.findIndex(
  //   (q) => q.dimensionId === segmentGroup.id
  // )
  // // Remove the segment from question options if it exists
  // if (questionIndex !== -1 && newConfig.rightAsk?.questions) {
  //   const question = newConfig.rightAsk.questions[questionIndex]
  //   const optionIndex = question.options.findIndex(
  //     (option) => option.segmentId === segment.id
  //   )
  //   if (optionIndex >= 0) {
  //     question.options.splice(optionIndex, 1)
  //   }
  // }
  // // Remove from signals
  // const signalIndex = segmentGroup.signals.findIndex(
  //   (s) => s.indicates === segment.id
  // )
  // if (signalIndex >= 0) {
  //   segmentGroup.signals.splice(signalIndex, 1)
  // }
  // // Remove from segments
  // const segmentIndex = segmentGroup.segments.findIndex(
  //   (s) => s.id === segment.id
  // )
  // if (segmentIndex >= 0) {
  //   segmentGroup.segments.splice(segmentIndex, 1)
  // }
  // // Update the entire config object atomically
  // nextTick(() => {
  //   config.value = newConfig
  //   configStore.debouncedUpdateConfig(newConfig)
  // })
}

export const toggleFavorite = (segmentGroup: Dimension) => {
  const segmentGroupStore = useSegmentGroupStore()
  const sg = segmentGroupStore.getById(segmentGroup.id)
  if (!sg) return
  sg.favorited = !sg.favorited
}

const hasSyncDefinition = (definition: LogicDefinition, partner: Partner) => {
  return definition.or.find((or) =>
    or.and.find(
      (and) => and.$type === 'customField' && and.$source === partner.name
    )
  )
}

export const signalForSegment = (segmentGroup: Dimension, segment: Segment) => {
  if (segmentGroup) {
    let signal = segmentGroup.signals.find(
      (signal) => signal.indicates === segment.id
    )
    if (!signal) {
      signal = {
        indicates: segment.id,
        weight: '1',
        definition: { or: [] }
      }
      segmentGroup.signals.push(signal)
    }
    return segmentGroup.signals.find(
      (signal) => signal.indicates === segment.id
    )
  }
}

export const repairSegmentGroup = (
  segmentGroup: Dimension,
  { partner }: { partner?: Partner } = {}
) => {
  const questionStore = useQuestionStore()
  const question = questionStore.getBySegmentGroupId(segmentGroup.id)
  if (question) {
    const otherSegment = getOtherSegmentForSegmentGroup(segmentGroup)
    if (question.otherOptions?.enabled) {
      if (!otherSegment) {
        createSegment(segmentGroup, 'INT_OTHER', makeId(idPrefixes.SEGMENT))
      }
      question.options.sort((a, b) => {
        const indexA = segmentGroup.segments.findIndex(
          (s) => s.id === a.segmentId
        )
        const indexB = segmentGroup.segments.findIndex(
          (s) => s.id === b.segmentId
        )
        return indexA - indexB
      })
    } else {
      if (otherSegment) {
        const idx = segmentGroup.segments.findIndex(
          (s) => s.id === otherSegment.id
        )
        if (idx >= 0) {
          segmentGroup.segments.splice(idx, 1)
        }

        const idx2 = segmentGroup.signals.findIndex(
          (s) => s.indicates === otherSegment.id
        )
        if (idx2 >= 0) {
          segmentGroup.signals.splice(idx2, 1)
        }

        const idx3 = question.options.findIndex(
          (o) => o.segmentId === otherSegment.id
        )
        if (idx3 >= 0) {
          question.options.splice(idx3, 1)
        }
      }
    }

    question.options.forEach((option) => {
      if (!segmentGroup.segments.find((s) => s.id === option.segmentId)) {
        question.options = question.options.filter(
          (o) => o.segmentId !== option.segmentId
        )
      }
    })
  }

  segmentGroup.segments.forEach((segment) => {
    signalForSegment(segmentGroup, segment)
  })

  segmentGroup.signals.forEach((signal) => {
    if (!segmentGroup.segments.find((s) => s.id === signal.indicates)) {
      segmentGroup.signals = segmentGroup.signals.filter(
        (s) => s.indicates !== signal.indicates
      )
    }
  })

  if (!partner) return

  if (segmentGroup.syncToCustomField) {
    segmentGroup.syncTo = partner.name
    segmentGroup.segments.forEach((segment) => {
      const signal = signalForSegment(segmentGroup, segment)

      if (signal) {
        if (!hasSyncDefinition(signal.definition, partner)) {
          signal.definition.or = [
            {
              and: [
                {
                  $type: 'customField',
                  $source: partner.name,
                  operator: 'equals',
                  customFieldId: segmentGroup.syncToCustomField
                }
              ]
            },
            ...signal.definition.or
          ]
        } else {
          const syncDefinition = hasSyncDefinition(signal.definition, partner)
          if (syncDefinition) {
            syncDefinition.and[0].customFieldId = segmentGroup.syncToCustomField
          }
        }
      }
    })
  } else {
    segmentGroup.signals.forEach((signal) => {
      // sometimes or is an empty array;
      const firstDefinition = signal.definition?.or.length ? signal.definition?.or[0]?.and[0] : null;
      if (
        firstDefinition &&
        firstDefinition.$source &&
        firstDefinition.$type === 'customField'
      ) {
        signal.definition.or.splice(0, 1)
      }
    })
  }
}
