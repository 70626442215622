import { defineStore } from 'pinia'
import { useQuery } from '@tanstack/vue-query'
import { noAuthApi } from '@/services/axiosApi'
import { Template } from '@/types/template'

export const useTemplateStore = defineStore('template', () => {
  const configStore = useConfigStore()
  const { currentTeamPid } = storeToRefs(configStore)

  const segmentGroups = ref([])
  const questions = ref([])
  const offers = ref([])
  const phrases = ref([])

  const cmsHost = import.meta.env.VITE_CMS_HOST

  const templatesQuery = useQuery({
    queryKey: ['templates', currentTeamPid],
    queryFn: () =>
      noAuthApi
        .get(`${cmsHost}/api/collections/templates/entries`)
        .then((res) => res.data),
    retry: false,
    enabled: !!cmsHost
  })

  const templates = computed(() => templatesQuery.data.value?.data || [])

  const transformedTemplates = computed(() => {
    return templates.value?.map((t) => {
      return {
        id: t.id,
        title: t.title,
        description: t.description,
        type: t.template_type.value,
        hidePreview: t.hide_preview,
        templateDetails: {
          json: JSON.parse(t.payload_json.value)
        },
        json: t.payload_json.value
      } as Template
    }) as Template[]
  })

  const getById = (id: string) => {
    return transformedTemplates.value?.find((t) => t.id === id)
  }

  return {
    templates,
    transformedTemplates,
    getById,
    segmentGroups,
    questions,
    offers,
    phrases
  }
})
