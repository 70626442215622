import { ROUTES } from '@/router/routeNames'

export default [
  {
    name: ROUTES.TEMPLATES,
    path: 'templates',
    component: () => import('./pages/TemplatesIndex.vue'),
    meta: {
      title: 'Templates',
      description:
        'Get started personalizing your marketing using our AI-backed templates.'
    }
  },
  {
    name: ROUTES.TEMPLATE,
    path: 'templates/:templateId',
    component: () => import('./pages/TemplateShow.vue'),
    meta: { fullScreen: true, backButton: ROUTES.TEMPLATES }
  }
]
