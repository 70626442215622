import type {
  FunnelDefinitionNode,
  FunnelDefinitionRootNode,
  Flow,
  Question
} from '@/types/config'

export const isEmptyFunnel = (
  funnelDefinition: FunnelDefinitionRootNode
): boolean => {
  return funnelDefinition.child.length === 0
}

export const flowHasQuestions = (
  funnelDefinition: FunnelDefinitionRootNode
) => {
  let hasQuestions = false
  funnelDefinitionEach(funnelDefinition, (node) => {
    if (node.type === 'sg' && node.ask) {
      hasQuestions = true
    }
  })
  return hasQuestions
}

export const isNodeSetUp = (node: FunnelDefinitionNode): boolean => {
  if (node.type === 'sg') {
    return node.segmentGroupId !== null
  }
  if (node.type === 'yesno') {
    return JSON.stringify(node.condition) !== JSON.stringify({ or: [] })
  }
  if (node.type === 'offer') {
    const offer = useOfferStore().getById(node.offerId)
    if (offer && isForm(offer) && !offerCanBeSubmitted(offer)) {
      return false
    }
    return node.offerId !== null
  }
  if (node.type === 'redirect') {
    return node.url !== ''
  }
  if (node.type === 'code') {
    return node.code !== ''
  }
  if (node.type === 'ai') {
    return false
  }
  return true
}

export const doesNodeTerminate = (node: FunnelDefinitionNode): boolean => {
  if (node.type === 'sg' && node.split && Array.isArray(node.split)) {
    if (node.segmentGroupId && node.split.length) {
      return node.split.every((split: any) =>
        split.child.some((child: any) => doesNodeTerminate(child))
      )
    } else {
      return false
    }
  }
  if (node.type === 'yesno') {
    return (
      node.yes.some((child: any) => doesNodeTerminate(child)) &&
      node.no.some((child: any) => doesNodeTerminate(child))
    )
  }
  if (node.type === 'random') {
    return node.split.every((split: any) =>
      split.child.some((child: any) => doesNodeTerminate(child))
    )
  }
  return (
    node.type === 'exit' ||
    node.type === 'offer' ||
    node.type === 'redirect' ||
    node.type === 'message'
  )
}

export const scoresInFlow = (funnelDefinition: FunnelDefinitionRootNode) => {
  let scores: string[] = []
  funnelDefinitionEach(funnelDefinition, (node) => {
    if (node.type === 'data') {
      node.actions.forEach((action) => {
        if (action.type === 'score' && action.score) {
          scores.push(action.score)
        }
      })
    }
  })

  return [...new Set(scores)]
}

export const questionsInFlow = (funnelDefinition: FunnelDefinitionRootNode) => {
  const questionStore = useQuestionStore()
  let questions: string[] = []
  funnelDefinitionEach(funnelDefinition, (node) => {
    if (node.type === 'sg' && node.ask && node.segmentGroupId) {
      questions.push(node.segmentGroupId)
    }
  })

  return questions
    .map((q) => questionStore.getBySegmentGroupId(q))
    .map((q) => ({
      id: q.id,
      label: stripHtml(renderText(q.text)),
      ...q
    }))
}

export const offersInFlow = (funnelDefinition: FunnelDefinitionRootNode) => {
  const offerStore = useOfferStore()
  let offers: string[] = []
  funnelDefinitionEach(funnelDefinition, (node) => {
    if (node.type === 'offer' && node.offerId) {
      offers.push(node.offerId)
    }
  })

  const OffersGottenById = offers.map((o) => offerStore.getById(o))

  return OffersGottenById.length
    ? OffersGottenById.filter(Boolean).map((o) => ({
        id: o.id,
        label: o.name,
        ...o
      }))
    : []
}

export const visibleThingsInFlow = (
  funnelDefinition: FunnelDefinitionRootNode
) => {
  return [
    ...questionsInFlow(funnelDefinition),
    ...offersInFlow(funnelDefinition)
  ]
}

export const dropdownFlows = computed(() => {
  const flowStore = useFlowStore()
  const flows = flowStore.flows
  return flows
    .map((flow) => ({
      label: flow.name,
      value: flow.id
    }))
    .sort((a, b) => a.label.localeCompare(b.label))
})

export const flowContainsQuestion = (flow: Flow, question: Question) => {
  const questions = questionsInFlow(flow.funnelDefinition)
  return questions.some((q) => q.id === question.id)
}
