import { defineStore } from 'pinia'
import { computed } from 'vue'
import { useConfigStore } from '@/stores/configStore'
import { makeId, idPrefixes } from '@/services/uuid'
import { klona } from 'klona'

export const useDomainNameStore = defineStore('domainName', () => {
  const configStore = useConfigStore()
  const { config } = storeToRefs(configStore)

  // Computed property for domains array with fallback to empty array
  const domains = computed(() => config.value?.domainNames || [])

  // Create new domain
  const create = ({ name }: { name: string }) => {
    domains.value.push(name)
    return name
  }

  const update = (name: string, index: number) => {
    domains.value[index] = name
  }

  // Delete domain
  const destroy = (name: string) => {
    const foundIndex = domains.value.findIndex((d) => d === name)
    if (foundIndex === -1) return

    domains.value.splice(foundIndex, 1)
  }

  const updateOrder = (updatedDomains: string[]) => {
    if (!config.value) return

    config.value.domainNames = updatedDomains
  }

  return {
    domains,
    create,
    update,
    destroy,
    updateOrder
  }
})
