export default {
  root: ({ props }) => ({
    class: [
      // Layout
      ''
    ]
  }),
  legend: ({ props }) => ({
    class: [
      // Font
      'font-semibold w-full',
      'leading-none',

      //Spacing
      'mb-4',

      // Color
      'text-primary-700 dark:text-surface-0/80',

      { 'text-base': props.size === null, 'text-2xl': props.size === 'lg' },

      // Transition
      'transition-none',


      'flex items-center space-x-2.5',

      // States
      { 'hover:bg-surface-100 dark:hover:bg-surface-800': props.toggleable }
    ]
  }),
  toggler: ({ props }) => ({
    class: [
      // Alignments
      'flex items-center justify-center',
      'relative',

      //Spacing
      { 'py-2 px-3': props.toggleable },

      // Shape
      { 'rounded-md': props.toggleable },

      // Color
      {
        'text-surface-700 dark:text-surface-200 hover:text-surface-900':
          props.toggleable
      },

      // States
      {
        'hover:text-surface-900 dark:hover:text-surface-100': props.toggleable
      },
      {
        'focus:outline-none focus:outline-offset-0 focus-visible:ring-1 focus-visible:ring-primary-400 dark:focus-visible:ring-primary-300':
          props.toggleable
      },

      // Misc
      {
        'transition-none cursor-pointer overflow-hidden select-none':
          props.toggleable
      }
    ]
  }),
  togglerIcon: {
    class: 'mr-2 inline-block'
  },
  legendTitle: ({ props }) => ({
    class: [
      'flex items-center justify-center leading-none',
      { 'py-2 px-3': !props.toggleable }
    ]
  }),
  content: ({ props }) => {
    return {
      class: [
        'p-0',
        {
          'p-4 rounded-md ring-1 ring-primary-500/20 bg-white dark:bg-slate-800 shadow space-y-4':
            !props.pt?.nobackground
        }
      ]
    }
  },
  transition: {
    enterFromClass: 'max-h-0',
    enterActiveClass:
      'overflow-hidden transition-[max-height] duration-1000 ease-[cubic-bezier(0.42,0,0.58,1)]',
    enterToClass: 'max-h-[1000px]',
    leaveFromClass: 'max-h-[1000px]',
    leaveActiveClass:
      'overflow-hidden transition-[max-height] duration-[450ms] ease-[cubic-bezier(0,1,0,1)]',
    leaveToClass: 'max-h-0'
  }
}

// export default {
//   root: {
//     class: [
//       // Spacing
//       'px-6 pt-6 pb-8',

//       // Shape
//       'rounded-md',

//       // Color
//       'border border-surface-200 dark:border-surface-700',
//       'bg-surface-0 dark:bg-surface-900',
//       'text-surface-700 dark:text-surface-0/80'
//     ]
//   },
//   legend: ({ props }) => ({
//     class: [
//       // Font
//       'font-semibold text-lg',
//       'leading-none',

//       //Spacing
//       'p-0 mb-[0.375rem] px-4 py-2',

//       // Shape
//       'rounded-md',

//       // Color
//       'text-primary-700 dark:text-surface-0/80',
//       'bg-surface-100 dark:bg-surface-900',

//       // Transition
//       'transition-none',

//       // States
//       { 'hover:bg-surface-100 dark:hover:bg-surface-800': props.toggleable }
//     ]
//   }),
//   toggler: ({ props }) => ({
//     class: [
//       // Alignments
//       'flex items-center justify-center',
//       'relative',

//       //Spacing
//       { 'py-2 px-3': props.toggleable },

//       // Shape
//       { 'rounded-md': props.toggleable },

//       // Color
//       {
//         'text-surface-700 dark:text-surface-200 hover:text-surface-900':
//           props.toggleable
//       },

//       // States
//       {
//         'hover:text-surface-900 dark:hover:text-surface-100': props.toggleable
//       },
//       {
//         'focus:outline-none focus:outline-offset-0 focus-visible:ring-1 focus-visible:ring-primary-400 dark:focus-visible:ring-primary-300':
//           props.toggleable
//       },

//       // Misc
//       {
//         'transition-none cursor-pointer overflow-hidden select-none':
//           props.toggleable
//       }
//     ]
//   }),
//   togglerIcon: {
//     class: 'mr-2 inline-block'
//   },
//   legendTitle: ({ props }) => ({
//     class: [
//       'flex items-center justify-center leading-none',
//       { 'py-2 px-3': !props.toggleable }
//     ]
//   }),
//   content: {
//     class: 'p-0'
//   },
//   transition: {
//     enterFromClass: 'max-h-0',
//     enterActiveClass:
//       'overflow-hidden transition-[max-height] duration-1000 ease-[cubic-bezier(0.42,0,0.58,1)]',
//     enterToClass: 'max-h-[1000px]',
//     leaveFromClass: 'max-h-[1000px]',
//     leaveActiveClass:
//       'overflow-hidden transition-[max-height] duration-[450ms] ease-[cubic-bezier(0,1,0,1)]',
//     leaveToClass: 'max-h-0'
//   }
// }
