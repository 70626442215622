import api from '@/services/axiosApi'

export default {
  posts: {
    queryFn: (teamPid: number) => {
      return api
        .get(`/api/${teamPid}/wordpress/posts`)
        .then((response) => response.data.data)
    }
  },
  pages: {
    queryFn: (teamPid: number) => {
      return api
        .get(`/api/${teamPid}/wordpress/pages`)
        .then((response) => response.data.data)
    }
  },
  taxonomies: {
    queryFn: (teamPid: number) => {
      return api
        .get(`/api/${teamPid}/wordpress/taxonomies`)
        .then((response) => response.data.data)
    }
  },
  tags: {
    queryFn: (teamPid: number) => {
      return api
        .get(`/api/${teamPid}/wordpress/taxonomies`)
        .then((response) => {
          const data = response.data.data
          const tags = data.find((tax) => tax.id === 'post_tag')?.terms || []
          return tags
        })
    }
  },
  categories: {
    queryFn: (teamPid: number) => {
      return api
        .get(`/api/${teamPid}/wordpress/taxonomies`)
        .then((response) => {
          const data = response.data.data
          const categories =
            data.find((tax) => tax.id === 'category')?.terms || []
          return categories
        })
    }
  }
}
