<template>
  <div>
    <div v-if="!minimal" class="w-auto">
      <router-link v-if="!isActive && currentTeam" :to="{ name: ROUTES.UPGRADE }" class="w-full">
        <Button label="Reactivate your account" class="w-full" severity="warning" outlined />
      </router-link>
      <template v-else>
        <SplitButton label="Publish to website" @click="attemptToPublish" :disabled="syncHappening || !currentTeam"
          :model="publishOptions" class="ml-2 w-full" />
      </template>
      <div v-if="currentTeam?.last_published_at" class="ml-2 mt-1 text-xs opacity-50 dark:text-white">
        Last published {{ timeAgo }}
      </div>
    </div>
    <div v-else>
      <ProgressSpinner v-if="syncHappening" style="width: 20px; height: 20px" strokeWidth="8" fill="transparent"
        animationDuration=".5s" />
    </div>

    <PreviewModal :visible="previewModalVisible" @update:visible="previewModalVisible = $event" />
    <PublishErrors :visible="publishErrorsVisible" @update:visible="publishErrorsVisible = $event"
      :validationErrors="validationErrors" />
    <SnapshotCreationModal :open="openCreateSnapshotModal" @updateVisible="openCreateSnapshotModal = $event" />
  </div>
</template>

<script setup>
import { PublishState } from '@/stores/configStore'

import { EyeIcon as PreviewIcon } from '@heroicons/vue/24/outline'
import SnapshotCreationModal from '@/components/common/SnapshotCreationModal.vue'
import { useIsFetching } from '@tanstack/vue-query'
import { UseTimeAgo } from '@vueuse/components'
import { comma } from 'postcss/lib/list'

const props = defineProps({ minimal: { type: Boolean, default: false } })

const authStore = useAuthStore()
const { user } = storeToRefs(authStore)

const configStore = useConfigStore()
const { publish } = configStore
const { currentTeam } = storeToRefs(configStore)
const { isUpdatingConfig, publishState, isDirty } = storeToRefs(configStore)

const subscriptionStore = useSubscriptionStore()
const { isActive } = storeToRefs(subscriptionStore)

let timeAgo = null
watch(currentTeam, (team) => {
  if (!team) return
  timeAgo = useTimeAgo(team.last_published_at, { showSecond: true, updateInterval: 1000 })
}, { immediate: true })


const isFetching = useIsFetching()

const validationErrors = ref([])
const openCreateSnapshotModal = ref(false)
const publishOptions = ref([
  {
    label: 'Preview on your website',
    icon: 'pi pi-eye',
    command: () => {
      previewModalVisible.value = true
    }
  }
  // {
  //   label: 'Save new snapshot',
  //   icon: 'pi pi-save',
  //   command: async () => {
  //     openCreateSnapshotModal.value = true
  //   }
  // },
  // { label: 'Load existing snapshot', icon: 'pi pi-folder' }
])

const previewModalVisible = ref(false)
const publishErrorsVisible = ref(false)
const availableToPublish = computed(() => {
  // return store.state.config.status === 'saved'
  return true
})

const syncHappening = computed(() => {
  return isFetching.value > 0 || isUpdatingConfig.value
})

const attemptToPublish = () => {
  const errors = configStore.validateForPublish()

  if (errors.length > 0) {
    validationErrors.value = errors
    publishErrorsVisible.value = true
  } else {
    publish()
  }
}

const buttonText = computed(() => {
  switch (publishState.value) {
    case PublishState.Loading:
      return 'Loading'
    case PublishState.Published:
      return 'Published'
    case PublishState.Dirty:
    case PublishState.Saved:
      return 'Publish To Site'
    default:
      return 'Unknown'
  }
})
</script>

<!-- <script>
import PrePublishIntegrate from '@/components/prepublish/PrePublishIntegrate.vue'
import Button from 'primevue/button'

export default {
  components: {
    PrePublishIntegrate,
    Button
  },

  data() {
    return {
      modalScreen: null
    }
  },

  computed: {
    availableToPublish() {
      if (process.env.NODE_ENV === 'development') return true
      return this.$store.state.config.status === 'saved'
    },

    buttonText() {
      if (process.env.NODE_ENV === 'development') return 'Publish (local)'
      switch (this.$store.state.config.status) {
        case 'loading':
          return 'Loading'

        case 'published':
          return 'Published'

        case 'dirty':
        case 'saving':
          return 'Saving...'

        case 'publishing':
          return 'Publishing...'

        // The only one that enables the button to work
        case 'saved':
        default:
          return 'Publish to site'
      }
    }
  },

  methods: {
    publishIfPossible() {
      if (
        this.$store.getters['config/draftHasLogicWhere'](
          (logic) => logic.$source === 'espplaceholder'
        )
      ) {
        if (this.$store.state.integrations.isLoading) {
          return // TODO don't be lazy and buggy
        }

        this.modalScreen = 'integrate'
        return
      }

      //                // Nope. We don't worry about snippet til after doing the actual publish.
      //                if (!this.$store.getters['gettingStarted/done_snippet']) {
      //                    this.modalScreen = 'snippet';
      //                    return;
      //                }

      this.modalScreen = null
      this.definitelyPublish()
    },

    definitelyPublish() {
      //                alert('Would publish now.');return;
      this.$emit('before')
      this.$store.dispatch('config/PUBLISH').then(
        (response) => this.$emit('success', response),
        (error) => this.$emit('failure', error)
      )
    }
  }
}
</script>

<style type="less"></style> -->
