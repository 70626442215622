<template>
  <div v-if="currentTeam || fakeTeam">
    <DefineNav v-slot="{ mobile }">
      <div class="flex flex-col gap-y-5 bg-white grow dark:bg-slate-950" :class="[
        mobile ? '' : 'border-r border-gray-200 dark:border-none',
        mobile
          ? ''
          : route.meta?.fullScreen && !isExpanded
            ? 'items-center'
            : 'px-6 pb-4 pt-2'
      ]">
        <div v-if="!mobile" class="flex justify-between items-center py-2 space-x-3"
          :class="{ 'mt-2': route.meta.fullScreen }">
          <div v-if="user?.is_admin" class="text-xl">
            <a :href="adminUrl">
              <img class="w-auto h-6 dark:grayscale dark:invert" src="https://t.rightmessage.com/logo.svg" />
            </a>
          </div>
          <router-link v-else :to="!route.params.teamPid
            ? '/'
            : {
              name: ROUTES.DASHBOARD,
              params: {
                teamPid: route.params.teamPid
              }
            }
            ">
            <img class="w-auto h-6" src="https://t.rightmessage.com/logo.svg" alt="Back to the Dashboard" />
          </router-link>

          <div class="flex flex-1 justify-between items-center space-x-1" v-if="!route.meta.fullScreen">
            <TeamDropdown />
            <div>
              <PlusIcon @click="newAccountDialogVisible = true" title="Create a new team"
                class="w-5 h-5 text-gray-400 transition-all cursor-pointer hover:text-primary-600" />
              <NewAccountDialog v-model="newAccountDialogVisible" />
            </div>
          </div>
        </div>
        <div v-if="route.meta.fullScreen && !mobile" class="flex flex-col flex-1 space-y-4">
          <button @click="
            router.push({
              name: route.meta.backButton || ROUTES.OFFERFUNNELS
            })
            ">
            <BackwardIcon class="w-6 h-6 opacity-50 transition duration-100 hover:opacity-100 dark:text-white" />
          </button>
          <PublishButton :minimal="true" />
          <div class="absolute bottom-4 left-1/2 transform -translate-x-1/2">
            <SunIcon v-if="!isDark" class="w-5 h-5 text-gray-400 cursor-pointer hover:text-gray-500" aria-hidden="true"
              @click="toggleDark()" />
            <MoonIcon v-else class="w-5 h-5 text-gray-400 cursor-pointer hover:text-gray-500" aria-hidden="true"
              @click="toggleDark()" />
          </div>
        </div>
        <nav v-if="!route.meta.fullScreen || mobile || isExpanded" class="flex flex-col flex-1">
          <ul role="list" class="flex flex-col flex-1 gap-y-7">
            <li>
              <ul role="list" class="-mx-2 space-y-0.5">
                <li class="pb-8">
                  <PublishButton />
                </li>
                <li v-for="item in navigation" :key="item.name">
                  <div v-if="item.type == 'divider'">
                    <div class="mt-10 mb-3 ml-3 text-xs font-bold uppercase text-primary-600">
                      {{ item.name }}
                    </div>
                  </div>
                  <router-link v-else :to="item.comingsoon || fakeTeam
                    ? {}
                    : {
                      ...item.to,
                      params: {
                        teamPid: route.params.teamPid
                      },
                      ...(item.query || {})
                    }
                    " :class="[
                      isCurrentRoute(item)
                        ? 'bg-gray-50 text-primary-600 dark:bg-slate-700/50 dark:text-primary-200'
                        : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600 dark:text-white/80 dark:hover:bg-slate-700/50 dark:hover:text-primary-200',
                      item.permission && !canAccess(item.permission)
                        ? 'opacity-50'
                        : '',
                      item.comingsoon ? 'cursor-not-allowed' : '',
                      'group flex items-center gap-x-3 rounded-md p-2 text-base font-semibold leading-6'
                    ]">
                    <component :is="item.icon" :class="[
                      isCurrentRoute(item)
                        ? 'text-indigo-600'
                        : 'text-gray-400 group-hover:text-indigo-600',
                      'h-5 w-5 shrink-0'
                    ]" aria-hidden="true" />
                    <span class="flex items-center">
                      {{ item.name }}

                      <span v-if="item.aside" class="ml-2.5" v-html="item.aside"></span>
                    </span>
                    <Tag v-if="item.permission && !canAccess(item.permission)" value="Upgrade" severity="success" />
                    <Tag v-else-if="item.comingsoon && !fakeTeam" value="Soon" severity="info" />
                    <Tag v-else-if="item.beta" value="Beta" severity="success" />
                  </router-link>

                  <div>
                    <ul role="list" class="flex flex-col flex-1 ml-9">
                      <li v-for="child in item.children" :key="child.name">
                        <a :href="child.href" :class="[
                          isCurrentRoute(child)
                            ? 'font-bold'
                            : 'text-gray-700 opacity-75 hover:text-indigo-600',
                          'group flex gap-x-3 rounded-md p-1 text-xs font-semibold leading-6'
                        ]">
                          {{ child.name }}
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </li>
            <li class="mt-auto">
              <div class="flex gap-x-4 items-center lg:gap-x-6">
                <router-link v-if="!fakeTeam" :to="{ name: ROUTES.SETTINGS }" type="button"
                  class="p-2.5 -m-2.5 text-gray-400 hover:text-gray-500">
                  <SettingsIcon class="w-5 h-5" aria-hidden="true" />
                </router-link>
                <FeatureOs />
                <SunIcon v-if="!isDark" class="w-5 h-5 text-gray-400 cursor-pointer hover:text-gray-500"
                  aria-hidden="true" @click="toggleDark()" />
                <MoonIcon v-else class="w-5 h-5 text-gray-400 cursor-pointer hover:text-gray-500" aria-hidden="true"
                  @click="toggleDark()" />

                <!-- Separator -->
                <div class="hidden lg:block lg:h-5 lg:w-px lg:bg-gray-200" aria-hidden="true" />

                <!-- Profile dropdown -->
                <Menu as="div" class="relative">
                  <MenuButton class="flex items-center p-1.5 -m-1.5">
                    <span class="sr-only">Open user menu</span>
                    <img class="w-6 h-6 bg-gray-50 rounded-full" :src="profilePhotoUrl" alt="" />
                    <span class="hidden lg:flex lg:items-center">
                      <ChevronDownIcon class="ml-2 w-4 h-4 text-gray-400" aria-hidden="true" />
                    </span>
                  </MenuButton>
                  <transition enter-active-class="transition duration-100 ease-out"
                    enter-from-class="opacity-0 transform scale-95" enter-to-class="opacity-100 transform scale-100"
                    leave-active-class="transition duration-75 ease-in"
                    leave-from-class="opacity-100 transform scale-100" leave-to-class="opacity-0 transform scale-95">
                    <MenuItems
                      class="absolute right-0 -top-10 z-10 py-2 -mt-28 w-32 bg-white rounded-md ring-1 shadow-lg origin-bottom-right ring-gray-900/5 focus:outline-none dark:bg-slate-800">
                      <MenuItem v-for="item in userNavigation" :key="item.name" class="cursor-pointer"
                        v-slot="{ active }">
                      <a @click="item.action" :class="[
                        active ? 'bg-gray-50 hover:dark:bg-gray-50' : '',
                        'block px-3 py-1 text-sm leading-6 text-gray-900 dark:text-white/90 dark:hover:text-gray-900'
                      ]">
                        {{ item.name }}
                      </a>
                      </MenuItem>
                    </MenuItems>
                  </transition>
                </Menu>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </DefineNav>
    <!-- mobile -->
    <div class="border-b border-surface-400/50 lg:hidden">
      <div class="flex justify-between p-3">
        <div>
          <HamburgerIcon class="w-6 h-6" @click="mobileNavVisible = true" />
        </div>
      </div>
      <Drawer v-model:visible="mobileNavVisible">
        <template #header>
          <div class="flex gap-2 items-center">
            <router-link v-if="!fakeTeam" :to="{
              name: ROUTES.DASHBOARD,
              params: {
                teamPid: route.params.teamPid
              }
            }">
              <img class="w-auto h-6" src="https://t.rightmessage.com/logo.svg" alt="Back to the Dashboard" />
            </router-link>
            <TeamDropdown />
          </div>
        </template>

        <ReuseNav :mobile="true" />
      </Drawer>
    </div>

    <!-- desktop-->
    <div :class="[{ 'lg:w-16': route.meta.fullScreen }]"
      class="hidden overflow-y-auto z-auto transition-all duration-200 transform lg:fixed lg:inset-y-0 lg:flex lg:flex-col"
      id="rm-sidebar">
      <div v-if="isLocal" class="px-4 h-2 text-sm text-center bg-yellow-400 dark:bg-yellow-900">&nbsp;</div>
      <ReuseNav :mobile="false" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { createReusableTemplate } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import {
  Cog6ToothIcon as SettingsIcon,
  SunIcon,
  MoonIcon,
  PlusIcon,
  ChevronDownIcon,
  ArrowLeftIcon as BackwardIcon,
  Bars3Icon as HamburgerIcon
} from '@heroicons/vue/24/outline'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ROUTES } from '@/router/routeNames'
import {
  generateNavigation,
  generateUserNavigation
} from '@/data/sidebarNavigation'
import { RouteLocation } from 'vue-router'

const props = defineProps({
  fakeTeam: {
    type: [Object, null],
    default: null
  }
})

const [DefineNav, ReuseNav] = createReusableTemplate()

const router = useRouter()
const route = useRoute()
const isLocal = import.meta.env.VITE_APP_ENV === 'development'

const isDark = useDark()
const toggleDark = useToggle(isDark)

const { canAccess: canAccessProxy } = usePermissionStore()

const canAccess = (permission: string) => {
  if (props.fakeTeam) {
    return true
  }
  return canAccessProxy(permission)
}

const { isExpanded } = useSidebarStore()

const authStore = useAuthStore()
const { user, profilePhotoUrl } = storeToRefs(authStore)
const configStore = useConfigStore()
const { currentTeam, owner } = storeToRefs(configStore)

const navigation = generateNavigation
const userNavigation = generateUserNavigation()

const mobileNavVisible = ref(false)
const newAccountDialogVisible = ref(false)

watch(
  () => route.fullPath,
  () => {
    mobileNavVisible.value = false
  }
)

const isCurrentRoute = (item: { to: RouteLocation }) => {
  if (!item || !item.to || !item.to.name) return false

  // Check if the current route matches exactly
  if (route.name === item.to.name) return true

  // Check if the current route is a child of the navigation item
  const currentRouteName = route.name
  const itemRouteName = item.to.name

  // Define parent-child relationships
  const parentChildMap = {
    [ROUTES.SEGMENTGROUPS]: [ROUTES.SEGMENTGROUP],
    [ROUTES.QUESTIONS]: [ROUTES.QUESTION],
    [ROUTES.INTEGRATIONS]: [ROUTES.INTEGRATION],
    [ROUTES.OFFERFUNNELS]: [ROUTES.OFFERFUNNEL],
    [ROUTES.WIDGETS]: [ROUTES.WIDGET, ROUTES.CREATEWIDGET],
    [ROUTES.OFFERS]: [ROUTES.OFFER],
    [ROUTES.FORMS]: [ROUTES.FORM],
    [ROUTES.CAMPAIGNS]: [ROUTES.CREATECAMPAIGN, ROUTES.CAMPAIGN],
    [ROUTES.EMAILS]: [ROUTES.EMAIL],
    [ROUTES.GOALS]: [ROUTES.CREATEGOAL]
  }

  // Check if the current route is a child of the navigation item
  return parentChildMap[itemRouteName]?.includes(currentRouteName) || false
}

const adminUrl = computed(() => {
  const backendUrl = import.meta.env.VITE_APP_BACKEND_HOST + '/admin'

  if (owner.value.email !== user.value?.email) {
    return backendUrl + `/users/${owner.value.id}`
  }

  return backendUrl
})
</script>
