<template>
    <div id="hellonextWidget" class="relative cursor-pointer">
        <div v-if="false" class="bg-red-500 h-2 w-2 rounded-full -top-1 -right-1 absolute"></div>
        <BellIcon class="w-5 h-5 text-gray-400 hover:text-gray-500" />
    </div>
</template>

<script setup>
import {
    BellIcon
} from '@heroicons/vue/24/outline'
onMounted(() => {
    const script = document.createElement('script')
    script.src = 'https://widgets-v3.featureos.app/widget.js'
    script.onload = () => {
        const widget = new window.HellonextWidget({
            type: "popover",
            openFrom: "right",
            theme: "light",
            accent: "#4353FF",
            selector: "#hellonextWidget",
            modules: [
                "changelog",
                "feature_requests"
            ],
            token: "4Om6zDcbIBM5JFtShu74gQ"
        })
        widget.init()
    }
    document.head.appendChild(script)
})
</script>
