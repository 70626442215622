<template>
  <Dialog v-model:visible="open" modal header="Create A New Project">
    <form @submit="createTeam" class="space-y-6 pb-8">
      <FormGroup label="Project Name">
        <InputText v-model="name" class="w-full" size="large" />
        <template #description>
          <span class="text-sm text-red-800">{{ errors.name }}</span>
        </template>
      </FormGroup>

      <FormGroup label="Website URL">
        <InputText v-model="website" class="w-full" size="large" />
        <template #description>
          <span class="text-sm text-red-800">{{ errors.website }}</span>
        </template>
      </FormGroup>

      <Button type="submit" label="Create Project" />
    </form>
  </Dialog>
</template>

<script setup lang="ts">
import { useForm } from 'vee-validate'
import { useField } from 'vee-validate'
import z from 'zod'
import { toTypedSchema } from '@vee-validate/zod'
import { Team } from '@/types/team'

const open = defineModel()

const newTeamSchema = toTypedSchema(
  z.object({
    name: z.string().min(1, 'Project name is required'),
    website: z
      .string()
      .transform((val) => {
        if (!val) return val
        // Remove any trailing slashes and everything after
        val = val.replace(/\/.*$/, '')
        // Add https:// if no protocol specified
        if (!val.startsWith('http://') && !val.startsWith('https://')) {
          val = `https://${val}`
        }
        return val
      })
      .pipe(
        z
          .string()
          .url({ message: 'Please enter a valid website address' })
          .refine(
            (val) => {
              try {
                const url = new URL(val)
                // Ensure there's at least one dot in the hostname (e.g., example.com)
                return url.hostname.includes('.') && !url.pathname.slice(1)
              } catch {
                return false
              }
            },
            { message: 'Please enter a valid domain (e.g., example.com)' }
          )
      )
  })
)

const { handleSubmit, errors, resetForm } = useForm({
  validationSchema: newTeamSchema,
  initialValues: {
    name: '',
    website: ''
  }
})

const { value: name } = useField('name')
const { value: website } = useField('website')

const teamStore = useTeamStore()

const createTeam = handleSubmit((values: Team) => {
  teamStore.create(values)
  open.value = false
  resetForm()
})
</script>
