const funnelDefinitionEachRun = (
  nodeRun: any[],
  callback: (...args: Array<any>) => any
) => {
  for (let i = nodeRun.length - 1; i >= 0; i--) {
    // We loop backwards because so a nuke doesn't set `i` off-by-1 in the array
    funnelDefinitionEach(nodeRun[i], callback, () => {
      nodeRun.splice(i, 1)
    })
  }
}

export const funnelDefinitionEach = (
  node: any,
  callback: (...args: Array<any>) => any,
  nuke?: (...args: Array<any>) => any
) => {
  let nuked = false
  callback(node, () => {
    if (nuke) {
      nuke()
      nuked = true
    }
  })

  if (nuked) {
    return
  }

  if (node.type === 'root') {
    funnelDefinitionEachRun(node.child, callback)
    return
  }

  if (
    node.type === 'sg' ||
    node.type === 'random' ||
    node.type === 'highscore'
  ) {
    if (node.split) {
      node.split.forEach((ns) => {
        funnelDefinitionEachRun(ns.child, callback)
      })
    }

    return
  }

  if (node.type === 'yesno') {
    funnelDefinitionEachRun(node.yes, callback)
    funnelDefinitionEachRun(node.no, callback)
    return
  }

  if (['offer', 'redirect', 'exit'].includes(node.type)) {
    return
  }

  return
}

export const funnelDefinitionForEachFlow = (
  callback: (...args: Array<any>) => any,
  nuke?: (...args: Array<any>) => any
) => {
  const flowStore = useFlowStore()
  flowStore.flows.forEach((flow) => {
    funnelDefinitionEach(flow.funnelDefinition, callback, nuke)
  })
}
