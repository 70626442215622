<template>
  <Dialog
    :visible="visible"
    @update:visible="$emit('update:visible', $event)"
    modal
    header="Preview New Changes"
    :style="{ width: '50vw' }">
    <div class="space-y-8"> 
      <div class="flex items-center gap-2">
        <InputText
          v-model="previewUrl"
          placeholder="https://..."
          size="large"
          class="flex-1" />
        <Button
          icon="pi pi-external-link"
          label="Open Preview"
          @click="openPreview"
          class="p-button-sm" />
      </div>

      <div class="past-urls">
        <h3 class="text-lg font-bold">Preview with a previous URL:</h3>
        <ul class="pt-4 space-y-4">
          <li
            v-for="(url, index) in pastUrls"
            :key="index"
            class="flex items-center justify-between">
            <div class="flex items-center gap-2 text-primary-500">
              <a
                :href="formattedUrl(url)"
                target="_blank"
                rel="noopener noreferrer"
                class="text-lg underline">
                {{ url }}
              </a>
              <OpenIcon
                class="w-4 h-4 cursor-pointer"
                @click="openPreview(url)" />
            </div>
            <div>
              <DeleteIcon
                class="w-5 h-5 opacity-50 cursor-pointer hover:opacity-100"
                @click="deleteUrl(index)" />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </Dialog>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStorage } from '@vueuse/core'
import {
  ArrowTopRightOnSquareIcon as OpenIcon,
  TrashIcon as DeleteIcon
} from '@heroicons/vue/24/outline'
import * as htmlFormatter from 'jsondiffpatch/formatters/html'

const emit = defineEmits(['update:visible'])
const props = defineProps({
  visible: {
    type: Boolean,
    required: true
  }
})

const previewUrl = ref('');
const pastUrls = useStorage('pastPreviewUrls', []);

const deleteUrl = (index) => {
  pastUrls.value.splice(index, 1);
}

const openPreview = () => {
  const url = previewUrl.value.trim()
  if (url && !pastUrls.value.includes(url)) {
    pastUrls.value.unshift(url)
    // Limit the list to the last 5 URLs
    pastUrls.value = pastUrls.value.slice(0, 10)
  }
  if (url) {
    emit('update:visible', false)
    window.open(formattedUrl(url), '_blank')
  }
}

const formattedUrl = (url) => {
  const urlObj = new URL(url)
  urlObj.searchParams.append('preview', 'true')
  urlObj.searchParams.append('debug', 'true')
  return urlObj.toString()
}
</script>
