import { defineStore } from 'pinia'
import { useConfigStore } from './configStore'
import { storeToRefs } from 'pinia'

export const useSubscriptionStore = defineStore('subscription', () => {
  const configStore = useConfigStore()
  const { owner } = storeToRefs(configStore)

  // Computed properties to access owner attributes
  const stripeStatus = computed(() => owner.value?.stripe_status)
  const trialEndsAt = computed(() => owner.value?.trial_ends_at)
  const isFreeAccount = computed(() => owner.value?.free_account)
  const isAdmin = computed(() => owner.value?.is_admin)
  const activeSubscription = computed(() => {
    return owner.value?.active_subscription
  })

  const hasValidSubscription = computed(() => {
    const subscription = owner.value?.active_subscription
    if (subscription) {
      if (subscription.stripe_status !== 'active') return false
      return !subscription.ends_at
    }

    return false
  })

  /**
   * Determines if the user has an active subscription
   * Active users are either:
   * 1. Free or admin accounts
   * 2. Have an active or trialing stripe subscription
   */
  const isActive = computed(() => {
    if (isTrialing.value) return true
    const activeStripeStatuses = ['trialing', 'active']
    return (
      isFreeAccount.value ||
      isAdmin.value ||
      activeStripeStatuses.includes(stripeStatus.value)
    )
  })

  /**
   * Determines if the user is currently in trial period
   */
  const isTrialing = computed(() => {
    if (owner.value?.on_trial) return true
    return stripeStatus.value === 'trialing'
  })

  const trialExpiresAt = computed(() => {
    if (owner.value?.on_trial) return owner.value?.trial_ends_at
    return null
  })

  return {
    stripeStatus,
    trialEndsAt,
    trialExpiresAt,
    isFreeAccount,
    isAdmin,
    isActive,
    isTrialing,
    activeSubscription,
    hasValidSubscription
  }
})
