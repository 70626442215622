import { ROUTES } from '@/router/routeNames'

export default [
  {
    name: ROUTES.SETTINGS,
    path: 'settings',
    component: () => import('./pages/SettingsIndex.vue')
  },
  {
    name: ROUTES.PROFILE,
    path: 'profile',
    component: () => import('./pages/ProfileShow.vue')
  },
  {
    name: ROUTES.UPGRADE,
    path: 'upgrade',
    component: () => import('./pages/Upgrade.vue')
  }
]
