export const createWidgetDefaults = (type: string, theme: string) => {
  let fontSize = {
    lineHeight: 1.5,
    headlineFontSize: '24px',
    formFieldFontSize: '13px',
    descriptionFontSize: '16px',
    answerButtonFontSize: '13px',
    submitButtonFontSize: '13px'
  }

  let structure = {
    formFieldSpacing: '8px',
    buttonBorderRadius: '0px',
    answerButtonSpacing: '8px',
    containerBorderWidth: '0px',
    containerBorderRadius: '8px'
  }

  let headerInfo = {
    isEnabled: false,
    style: 'left'
  }

  let style = {
    shadowSize: 'lg'
  }

  if (type === 'bar') {
    fontSize.headlineFontSize = '17px'
    fontSize.formFieldFontSize = '15px'
    fontSize.submitButtonFontSize = '16px'
    structure.containerBorderWidth = '2px'
  }

  if (type === 'toaster') {
    structure.buttonBorderRadius = '4px'
    structure.answerButtonSpacing = '11px'
    fontSize.answerButtonFontSize = '14px'
    fontSize.submitButtonFontSize = '15px'
  }

  if (type === 'popup') {
    structure.formFieldSpacing = '13px'
    fontSize.submitButtonFontSize = '16px'
  }

  let placement = null
  if (type === 'bar') {
    placement = 'top'
  } else if (type === 'toaster') {
    placement = 'right'
  }

  return {
    theme,
    palette: {
      borderColor: '#5d6b91',
      contrastColor: '#3182CE',
      backgroundColor: '#ffffff',
      textOnContrastColor: '#EBF8FF',
      textOnBackgroundColor: '#1A202C'
    },
    fontSize,
    customCss: '',
    placement,
    structure,
    headerInfo,
    style,
    displayTargets: {
      phones: true,
      desktop: true,
      tablets: true
    },
    backButtonLabel: '← Go back',
    backButtonEnabled: false,
    buttonAnimationTrigger: ''
  }
}
